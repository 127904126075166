import React from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function Select({
  label = '',
  name,
  value,
  onChange,
  required = false,
  options = [],
  sx = {},
  disabled = false,
  multiple = false,
  limitDisplay = 3,
}) {
  return (
    <TextField
      id='select'
      select
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      disabled={disabled}
      SelectProps={{
        multiple,
        renderValue: selected => {
          if (multiple && Array.isArray(selected)) {
            const selectedOptions = selected.map(
              id => options.find(option => option.value === id)?.label
            );
            if (selectedOptions.length > limitDisplay) {
              return `${selectedOptions.slice(0, limitDisplay).join(', ')}... (${
                selectedOptions.length
              }) seleccionados`;
            }
            return selectedOptions.join(', ');
          }
          // == for number and string comparison
          // eslint-disable-next-line eqeqeq
          return options.find(option => option.value == selected)?.label || '';
        },
      }}
      sx={{ textAlign: 'start', ...sx }}>
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import Form from '../../style-components/Form/Form.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import Select from '../../style-components/Form/Select.jsx';
import Switch from '../../style-components/Form/Switch.jsx';
import FileUpload from '../../style-components/Form/FileUpload.jsx';
import { postImages, postImagesUpload, postImage, patchImage } from '../../models/images.js';
import { useGetLearnunits, postLearnunit } from '../../models/learnunits.js';
import { getCurrentUser, getUsersWithPagination } from '../../models/user.js';
import { normalize } from '../../utils/normalize.js';

const styles = {
  learunitInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
};

function ImageForm({ method = 'POST', type = 'url', setOpen, image }) {
  const [learnunits, setLearnunits] = useState([]);
  const [formState, setFormState] = useState({
    id: image?.id ?? null,
    learnunit_id: image?.learnunit_id ?? '',
    details: image?.details ?? '',
    url: image?.url ?? '',
    type: image?.type ?? '2D-Image',
    share: image?.share ?? true,
    owner_id: image?.owner_id ?? '',
  });
  const [images, setImages] = useState(null);
  const [selectedLearnunit, setSelectedLearnunit] = useState('');
  const [newLearnunit, setNewLearnunit] = useState('');
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [role, setRole] = useState('TEACHER');

  const [learnunitsData, learnunitsDataLoading] = useGetLearnunits();

  useEffect(() => {
    getCurrentUser()
      .then(currentUser => {
        setRole(currentUser.role);
        if (currentUser.role === 'ADMIN') {
          getUsersWithPagination(1, 9999, 'TEACHER').then(res =>
            setTeachers(
              res.users.map(user => ({
                value: user.id,
                label: `${user.firstnames} ${user.lastnames}`,
              }))
            )
          );
        }
      })
      .catch();
  }, []);

  useEffect(() => {
    if (learnunitsData && !learnunitsDataLoading) {
      setLearnunits(
        learnunitsData.data?.map(learnunit => ({ value: learnunit.id, label: learnunit.name })) ||
          []
      );
      if (formState.learnunit_id) {
        setSelectedLearnunit(formState.learnunit_id);
      }
    }
  }, [learnunitsData, learnunitsDataLoading]);

  const handleSubmit = async () => {
    if (!selectedLearnunit && !newLearnunit) {
      alert('Debes ingresar una unidad de aprendizaje. Esta puede ser preexistente o nueva.');
      return;
    }

    setLoading(true);
    let learnunit_id;
    if (newLearnunit) {
      let duplicate = false;
      learnunits.data.forEach(learnunit => {
        if (normalize(learnunit.label) === normalize(newLearnunit)) {
          learnunit_id = learnunit.value;
          duplicate = true;
        }
      });
      if (!duplicate) {
        const response = await postLearnunit({ name: newLearnunit });
        if (response.status === 200 || response.status === 201) {
          learnunit_id = response.data[0].id;
        } else {
          alert('Error al crear la unidad de aprendizaje.');
          setLoading(false);
          return;
        }
      }
    } else {
      learnunit_id = selectedLearnunit;
    }

    if (type === 'url' && method === 'POST') {
      const response = await postImage({ ...formState, learnunit_id });
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        alert('Imagen agregada con éxito.');
        setOpen(false);
        window.location.reload();
      } else {
        alert('Error al añadir la imagen.');
      }
      return;
    }
    if (method === 'PATCH') {
      const response = await patchImage(formState.id, formState);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        alert('Imagen editada con éxito.');
        setOpen(false);
        window.location.reload();
      } else {
        alert('Error al editar la imagen.');
      }
      return;
    }

    const result = await postImagesUpload(images);
    if (!(result.status === 200 || result.status === 201)) {
      alert(
        'Error al subir las imágenes. Intenta subir menos imágenes a la vez y/o comprimir las imágenes.'
      );
      setLoading(false);
      return;
    }

    const resultImages = result.data;
    const imagesToUpload = [];
    for (let i = 0; i < images.length; i++) {
      imagesToUpload.push({
        url: resultImages[i].url,
        details: images[i].name.substring(0, images[i].name.lastIndexOf('.')),
        type: formState.type,
        share: formState.share,
        owner_id: role === 'ADMIN' ? formState.owner_id : null,
        learnunit_id,
        bucketPath: resultImages[i].bucketPath,
        urlExpiracyDatetime: resultImages[i].urlExpiracyDatetime,
      });
    }

    const response = await postImages(imagesToUpload);
    setLoading(false);
    if (response.status === 200 || response.status === 201) {
      alert('Imágenes agregadas con éxito.');
      setOpen(false);
      window.location.reload();
    } else {
      alert('Error al añadir las imágenes. Intenta subir menos imágenes a la vez.');
    }
  };

  const handleChange = e => {
    const { name, type, checked, value } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormState({
      ...formState,
      [name]: newValue,
    });
  };

  const imagesSelected = event => {
    setImages(event.target.files);
  };

  return (
    <Form
      title={`${method === 'POST' ? 'Añadir' : 'Editar'} imagen`}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      {type === 'url' ? (
        <>
          <TextField
            label='Nombre'
            name='details'
            value={formState.details}
            onChange={handleChange}
            required
          />
          <TextField
            label='URL'
            name='url'
            value={formState.url}
            onChange={handleChange}
            required
          />
        </>
      ) : (
        <div style={styles.learunitInputContainer}>
          <FileUpload label='Subir imágenes' onChange={imagesSelected} required multiple />
          {images ? (
            <Typography>
              {images?.length === 1 ? images[0].name : `${images?.length} imágenes seleccionadas.`}
            </Typography>
          ) : null}
        </div>
      )}
      <div>
        <Typography>Seleccione la unidad de aprendizaje (preexistente o nueva) *</Typography>
        <div style={styles.learunitInputContainer}>
          <Select
            name='learnunits_id'
            label='Unidad Preexistente'
            value={selectedLearnunit}
            onChange={({ target }) => {
              setSelectedLearnunit(target.value);
              setNewLearnunit('');
            }}
            options={learnunits}
          />
          <TextField
            name='name'
            label='Unidad Nueva'
            value={newLearnunit}
            onChange={({ target }) => {
              setSelectedLearnunit('');
              setNewLearnunit(target.value);
            }}
          />
        </div>
      </div>
      <Select
        name='type'
        label='Tipo'
        value={formState.type}
        onChange={handleChange}
        required
        options={[
          { value: '2D-Image', label: 'Imagen 2D' },
          { value: '3D-Image', label: 'Imagen 3D' },
        ]}
      />
      <Switch
        name='share'
        label='Compartir con otros profesores'
        value={formState.share}
        onChange={handleChange}
        required
      />
      {role === 'ADMIN' ? (
        <Select
          name='owner_id'
          label='Profesor'
          value={formState.owner_id}
          onChange={handleChange}
          options={teachers}
        />
      ) : null}
    </Form>
  );
}
export default ImageForm;

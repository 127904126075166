function getDateFromISODate(date) {
    if (!date) return "";
    return new Date(date).toISOString().split("T")[0];
}

function getTimeFromISODate(date) {
    if (!date) return "";
    return new Date(date).toISOString().split("T")[1].split(".")[0];
}

function getISODateFromTime(time) {
    if (!time) return "";
    return new Date("1970-01-01T" + time + "Z").toISOString();
}

function getISODateFromDate(date) {
    if (!date) return "";
    return new Date(date + "T00:00:00Z").toISOString();
}

function getISODateFromTimeAndDate(time, date) {
    if (!time || !date) return "";
    return new Date(date + "T" + time + "Z").toISOString();
}

export {
    getDateFromISODate,
    getTimeFromISODate,
    getISODateFromTime,
    getISODateFromDate,
    getISODateFromTimeAndDate
}

import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CustomCheckbox({ label = '', defaultChecked, onChange, checked }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox onChange={onChange} defaultChecked={defaultChecked} checked={checked} />}
        label={label}
      />
    </FormGroup>
  );
}

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import Form from '../../style-components/Form/Form.jsx';
import Select from '../../style-components/Form/Select.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import DatePicker from '../../style-components/Form/DatePicker.jsx';
import { postLearnunit, useGetLearnunits } from '../../models/learnunits.js';
import { postCourseLearnunit } from '../../models/course.js';
import { normalize } from '../../utils/normalize.js';

const styles = {
  learunitInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
};

const NewLearnunit = ({ course_id, setOpen }) => {
  const [learnunits, setLearnunits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [learnunitsData, learnunitsDataLoading] = useGetLearnunits();

  const [formState, setFormState] = useState({
    course_id,
    starting_date: '',
    ending_date: '',
  });

  const [selectedLearnunit, setSelectedLearnunit] = useState('');
  const [newLearnunit, setNewLearnunit] = useState('');

  useEffect(() => {
    if (learnunitsData && !learnunitsDataLoading) {
      setLearnunits(
        learnunitsData.data?.map(learnunit => ({ value: learnunit.id, label: learnunit.name })) ||
          []
      );
    }
  }, [learnunitsData, learnunitsDataLoading]);

  const handleSubmit = async () => {
    if (!selectedLearnunit && !newLearnunit) {
      alert('Debe seleccionar o añadir una unidad de aprendizaje.');
      return;
    }
    if (!formState.starting_date || !formState.ending_date) {
      alert('Debe seleccionar una fecha de inicio y una fecha de fin.');
      return;
    }

    setLoading(true);
    if (newLearnunit) {
      let duplicate = false;
      learnunits.forEach(learnunit => {
        if (normalize(learnunit.label) === normalize(newLearnunit)) {
          formState.learnunit_id = learnunit.value;
          duplicate = true;
        }
      });
      if (!duplicate) {
        const responseLearnunit = await postLearnunit({ name: newLearnunit });
        if (responseLearnunit.status !== 200 && responseLearnunit.status !== 201) {
          setLoading(false);
          alert('Error al añadir la unidad de aprendizaje, intente de nuevo.');
          return;
        } else {
          formState.learnunit_id = responseLearnunit.data[0].id;
        }
      }
    } else {
      formState.learnunit_id = selectedLearnunit;
    }

    // POST COURSE_LEARNUNIT
    const response = await postCourseLearnunit(formState);
    setLoading(false);
    if (response.status !== 200 && response.status !== 201) {
      alert('Error al añadir la unidad de aprendizaje, intente de nuevo.');
      return;
    }
    alert('Unidad de aprendizaje añadida correctamente.');
    setOpen(false);
    window.location.reload();
  };

  return (
    <Form title='Añadir unidad de aprendizaje' onSubmit={handleSubmit} onClose={() => setOpen(false)} loading={loading}>
      <div>
        <Typography>Seleccione la unidad de aprendizaje (preexistente o nueva) *</Typography>
        <div style={styles.learunitInputContainer}>
          <Select
            name='learnunits_id'
            label='Unidad Preexistente'
            value={selectedLearnunit}
            onChange={({ target }) => {
              setSelectedLearnunit(target.value);
              setNewLearnunit('');
            }}
            options={learnunits}
          />
          <TextField
            name='name'
            label='Unidad Nueva'
            value={newLearnunit}
            onChange={({ target }) => {
              setSelectedLearnunit('');
              setNewLearnunit(target.value);
            }}
          />
        </div>
      </div>
      <DatePicker
        name='starting_date'
        value={formState.starting_date}
        setFormState={setFormState}
        label='Fecha de inicio'
        required
        maxDate={formState.ending_date}
      />
      <DatePicker
        name='ending_date'
        value={formState.ending_date}
        setFormState={setFormState}
        label='Fecha de término'
        required
        minDate={formState.starting_date}
      />
    </Form>
  );
};

export default NewLearnunit;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Modal from '../Modal.jsx';
import QuestionForm from '../../components/Questions/QuestionForm.jsx';
import Loading from '../Loading.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Select from '../Form/Select.jsx';
import Checkbox from '../Form/Checkbox.jsx';
import ImageComponent from '../ImageComponent.jsx';
import {
  useGetQuestions,
  deleteQuestion,
  getQuestionByEvaluation,
} from '../../models/questions.js';
import { useGetLearnunits } from '../../models/learnunits.js';
import { getCurrentUser, useGetUsersWithPagination } from '../../models/user.js';
import { useGetAllEvaluations } from '../../models/evaluations.js';

const correctAnswerVF = {
  0: 'F',
  1: 'V',
};

const eval_type = {
  'Pre-Test': 'Pre-Test',
  'Post-Test': 'Post-Test',
  Practice: 'Práctica',
  Tournament: 'Torneo',
  Community: 'Comunidad',
};

const QuestionsTable = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [learnunitFilter, setLearnunitFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [questionsData, questionsDataLoading] = useGetQuestions(
    page,
    itemsPerPage,
    learnunitFilter,
    typeFilter,
    searchFilter
  );
  const [learnunitsData, learnunitsDataLoading] = useGetLearnunits();
  const [learnunitsObject, setLearnunitsObject] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: questions.length,
  });
  const [showImages, setShowImages] = useState(true);
  const [open, setOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [teachers, teachersDataLoading] = useGetUsersWithPagination(1, 99999, 'TEACHER');
  const [teachersDict, setTeachersDict] = useState({});
  const [evaluations, loadingEvaluations] = useGetAllEvaluations();
  const [evaluationsObject, setEvaluationsObject] = useState({});

  const options = [{ value: '', label: <em>Todas</em> }];
  if (learnunitsData) {
    learnunitsData?.data?.forEach(learnunit => {
      options.push({ value: learnunit.id, label: learnunit.name });
    });
  }

  function handleLearnunit(option) {
    setLearnunitFilter(option.target.value);
    setPage(1);
    setLoading(true);
  }

  function handleType(option) {
    setTypeFilter(option.target.value);
    setPage(1);
    setLoading(true);
  }

  function handleSearch(option) {
    setSearchFilter(option.target.value);
    setPage(1);
    setLoading(true);
  }

  useEffect(() => {
    if (questionsData && !questionsDataLoading) {
      setQuestions(questionsData.questions);
      setPagination({
        currentPage: questionsData.pagination.currentPage,
        itemsPerPage: itemsPerPage,
        totalPages: questionsData.pagination.pages,
      });
      setLoading(false);
    }
  }, [questionsData, questionsDataLoading]);

  useEffect(() => {
    if (learnunitsData?.data && !learnunitsDataLoading) {
      for (let i = 0; i < learnunitsData.data.length; i++) {
        learnunitsObject[learnunitsData.data[i].id] = learnunitsData.data[i].name;
      }
      setLearnunitsObject(learnunitsObject);
    }
  }, [learnunitsData, learnunitsDataLoading]);

  useEffect(() => {
    getCurrentUser()
      .then(user => {
        setUserRole(user.role);
      })
      .catch();
  }, []);

  useEffect(() => {
    if (teachers?.users && !teachersDataLoading) {
      const teachersDictTemp = {};
      teachers?.users?.forEach(teacher => {
        teachersDictTemp[teacher.id] = `${teacher.firstnames} ${teacher.lastnames}`;
      });
      setTeachersDict(teachersDictTemp);
    }
  }, [teachers, teachersDataLoading]);

  const getQuestionsByEvaluations = async () => {
    const promises = [];
    for (let i = 0; i < evaluations.length; i++) {
      promises.push(getQuestionByEvaluation(evaluations[i].id));
    }
    const evaluationsQuestions = await Promise.all(promises).catch(err => {
      return [];
    });

    const tempEvaluationsObject = {};
    for (let i = 0; i < evaluationsQuestions.length; i++) {
      for (let j = 0; j < evaluationsQuestions[i].length; j++) {
        if (!tempEvaluationsObject[evaluationsQuestions[i][j].id]) {
          tempEvaluationsObject[evaluationsQuestions[i][j].id] =
            eval_type[evaluations[i].eval_type];
        } else {
          if (
            !tempEvaluationsObject[evaluationsQuestions[i][j].id].includes(
              eval_type[evaluations[i].eval_type]
            )
          ) {
            tempEvaluationsObject[evaluationsQuestions[i][j].id] += `, ${
              eval_type[evaluations[i].eval_type]
            }`;
          }
        }
      }
    }
    setEvaluationsObject(tempEvaluationsObject);
  };

  useEffect(() => {
    if (evaluations && !loadingEvaluations) {
      getQuestionsByEvaluations();
    }
  }, [evaluations, loadingEvaluations]);

  const handleDelete = async questionId => {
    const confirmation = window.confirm('¿Está seguro que desea eliminar la pregunta?');
    if (confirmation) {
      const response = await deleteQuestion(questionId);
      if (response.status === 200 || response.status === 201) {
        alert('Pregunta eliminada.');
        window.location.reload();
      } else {
        alert('Error al eliminar la pregunta. Intente de nuevo.');
      }
    }
  };

  return (
    <div className='antialiased font-sans'>
      <Modal open={open} setOpen={setOpen}>
        <QuestionForm setOpen={setOpen} method='PATCH' question={questionToEdit} />
      </Modal>
      <div className='w-full divide-y divide-primary'>
        <HeaderContainer
          title='Preguntas'
          search={{ handleSearch: handleSearch }}
          actions={[
            {
              title: 'Importar preguntas',
              icon: <AddIcon />,
              handleClick: () => navigate('/questions/import_questions'),
            },
          ]}
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <div className='flex justify-between items-center mb-4'>
            <div className='flex gap-4'>
              <Select
                name='learnunit'
                label='Unidad de Aprendizaje'
                value={learnunitFilter}
                onChange={handleLearnunit}
                options={options}
                sx={{ minWidth: '210px' }}
              />
              <Select
                name='type'
                label='Tipo'
                value={typeFilter}
                onChange={handleType}
                options={[
                  { value: '', label: <em>Todos</em> },
                  { value: 'ALTERNATIVAS', label: 'ALTERNATIVAS' },
                  { value: 'V/F', label: 'V/F' },
                  { value: 'DESARROLLO', label: 'DESARROLLO' },
                ]}
                sx={{ minWidth: '210px' }}
              />
            </div>
            <Checkbox
              label='Ver imágenes'
              defaultChecked
              onChange={() => setShowImages(!showImages)}
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {showImages ? <TableHeader>Imagen</TableHeader> : null}
                <TableHeader>Pregunta</TableHeader>
                <TableHeader>Respuesta correcta</TableHeader>
                <TableHeader>Tipo</TableHeader>
                <TableHeader>Tiempo (seg.)</TableHeader>
                <TableHeader>Unidad de aprendizaje</TableHeader>
                {userRole === 'ADMIN' ? <TableHeader>Propietario</TableHeader> : null}
                <TableHeader>Evaluación asociada</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {questionsDataLoading || loading ? (
                <TableRow>
                  <TableCell colSpan='9'>
                    <div className='flex justify-center items-center h-full'>
                      <Loading />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                questions.map(question => (
                  <TableRow key={question.id}>
                    {showImages ? (
                      <TableCell>
                        {question.image_id ? (
                          <ImageComponent image_id={question.image_id} />
                        ) : (
                          'Sin imagen asociada'
                        )}
                      </TableCell>
                    ) : null}
                    <TableCell>{question.content}</TableCell>
                    <TableCell>
                      {question.question_type === 'ALTERNATIVAS'
                        ? question.possible_answers[question.correct_answer]
                        : question.question_type === 'V/F'
                        ? correctAnswerVF[question.correct_answer]
                        : question.possible_answers.join(' | ')}
                    </TableCell>
                    <TableCell>{question.question_type}</TableCell>
                    <TableCell>{question.time_to_answer}</TableCell>
                    <TableCell>
                      {learnunitsDataLoading ? (
                        <Loading />
                      ) : (
                        learnunitsObject[question.learnunit_id]
                      )}
                    </TableCell>
                    {userRole === 'ADMIN' ? (
                      <TableCell>{teachersDict[question.owner_id]}</TableCell>
                    ) : null}
                    <TableCell>{evaluationsObject[question.id]}</TableCell>
                    <TableCell>
                      <div className='flex w-full justify-between gap-2 items-center'>
                        <TableButton
                          variant={'edit'}
                          onClick={() => {
                            setQuestionToEdit(question);
                            setOpen(true);
                          }}>
                          <EditOutlinedIcon />
                        </TableButton>
                        <TableButton variant={'delete'} onClick={() => handleDelete(question.id)}>
                          <DeleteOutlinedIcon color='red' />
                        </TableButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Pagination
            pagination={pagination}
            setPage={setPage}
            setLoading={setLoading}
            setItemPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionsTable;

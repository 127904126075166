import React, { useState } from 'react';
import { BiPoll } from 'react-icons/bi';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Modal from '../Modal.jsx';
import UniversityForm from '../../components/Universities/UniversityForm.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import { SortData } from '../../components/Custom-UI/Tables/Filters.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import { deleteUniversity } from '../../models/universities.js';

const UniversitiesTable = props => {
  const [universities, setUniversities] = useState(props.universities ? props.universities : []);
  const dashboard = props.dashboard ? props.dashboard : false;

  const [searchValue, setSearchValue] = useState('');
  const [method, setMethod] = useState('POST');
  const [universityToEdit, setUniversityToEdit] = useState({});
  const [open, setOpen] = useState(false);

  function sortDataByPath(path, direction) {
    const sortedData = SortData(universities, path, direction);
    setUniversities(sortedData);
  }

  function handleSearch(option) {
    setSearchValue(option.target.value?.toLowerCase());
  }

  const handleDelete = async UniversityId => {
    const confirmation = window.confirm('¿Estás seguro que quieres eliminar esta universidad?');
    if (confirmation) {
      const response = await deleteUniversity(UniversityId);
      if (response.status === 200) {
        alert('Universidad eliminada');
        window.location.reload();
      } else {
        alert('Error al eliminar la universidad. Intente de nuevo.');
      }
    }
  };

  return (
    <div className='antialiased font-sans w-full'>
      <Modal open={open} setOpen={setOpen}>
        <UniversityForm setOpen={setOpen} method={method} university={universityToEdit} />
      </Modal>
      <div className='divide-y divide-primary'>
        <HeaderContainer
          title='Universidades'
          search={{ handleSearch: handleSearch }}
          actions={
            dashboard
              ? false
              : [
                  {
                    title: 'Añadir Universidad',
                    icon: <AddIcon />,
                    handleClick: () => {
                      setUniversityToEdit({});
                      setMethod('POST');
                      setOpen(true);
                    },
                  },
                ]
          }
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader
                  className='w-[26rem]'
                  sortable
                  onSort={direction => sortDataByPath('name', direction)}>
                  Nombre
                </TableHeader>
                <TableHeader>País</TableHeader>
                <TableHeader sortable onSort={direction => sortDataByPath('city', direction)}>
                  Ciudad
                </TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {universities.map(university =>
                university.name.toLowerCase().includes(searchValue) ? (
                  <TableRow key={university.id}>
                    <TableCell>{university.name}</TableCell>
                    <TableCell type={'name'}>{university.country}</TableCell>
                    <TableCell type={'name'}>{university.city}</TableCell>
                    {dashboard ? (
                      <TableCell>
                        <TableButton
                          variant={'text'}
                          onClick={() => {
                            props.setUniversityId(university.id);
                            props.setUniversityName(university.name);
                          }}>
                          <div className='inline-flex items-center my-2'>
                            <div className='mr-1 mt-[0.1rem]'>
                              <BiPoll />
                            </div>
                            Estadísticas
                          </div>
                        </TableButton>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <div className='flex w-full justify-between gap-2 items-center'>
                          <TableButton
                            variant={'edit'}
                            onClick={() => {
                              setUniversityToEdit(university);
                              setMethod('PATCH');
                              setOpen(true);
                            }}>
                            <EditOutlinedIcon />
                          </TableButton>
                          <TableButton
                            variant={'delete'}
                            onClick={() => handleDelete(university.id)}>
                            <DeleteOutlinedIcon />
                          </TableButton>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ) : null
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default UniversitiesTable;

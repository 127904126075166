import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PrimaryButton from '../components/Custom-UI/Button.jsx';
import Breadcrumb from '../components/Custom-UI/Breadcrumb.jsx';
import IconButton from '@mui/material/IconButton';

const HeaderContainer = ({ title, breadcrumb, search, actions = [] }) => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const handleSearchClick = () => {
    search.handleSearch({ target: { value: searchValue } });
  };

  return (
    <div className='grid grid-cols-1 md:grid-cols-5 grid-rows-2 md:grid-rows-1 grid-row-2 py-4 px-4 sm:px-8 items-center'>
      <div className='col-span-1 font-semibold leading-tight text-left text-2xl text-primary'>
        {!breadcrumb ? (
          <h1>{title}</h1>
        ) : (
          <Breadcrumb items={breadcrumb.items} maxItems={breadcrumb.maxItems} />
        )}
      </div>
      <div className='col-span-4'>
        <div className='flex items-center space-x-4 justify-between md:justify-end w-full'>
          {search && (
            <FormControl
              sx={{ m: 0, width: '25ch', '& .MuiOutlinedInput-root': { borderRadius: '50px' } }}
              variant='outlined'>
              <OutlinedInput
                className='h-10'
                id='outlined-adornment-weight'
                placeholder='Buscar'
                color='primary'
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearchClick();
                  }
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton aria-label='search' color='primary' onClick={handleSearchClick}>
                      <SearchOutlinedIcon color='primary' />
                    </IconButton>
                  </InputAdornment>
                }
                aria-describedby='outlined-weight-helper-text'
              />
            </FormControl>
          )}

          {actions.map(action => (
            <PrimaryButton
              key={action.title || 'Volver'}
              variant='contained'
              onClick={action?.handleClick || (() => navigate(-1))}
              startIcon={action?.icon || <KeyboardArrowLeftIcon />}>
              {action?.title || 'Volver'}
            </PrimaryButton>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderContainer;

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import Select from '../Form/Select.jsx';
import FileUpload from '../Form/FileUpload.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import { getAllImages } from '../../models/images';

const ImportQuestionsTable = ({ questions, images, setImages }) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: Math.ceil(questions.length / itemsPerPage),
  });
  const [, setLoading] = useState(false);
  const [deviceFile, setDeviceFile] = useState(false);
  const [bankImages, setBankImages] = useState([]);
  const [bankImagesIdToIdx, setBankImagesIdToIdx] = useState({});

  useEffect(() => {
    setPagination({
      currentPage: page,
      itemsPerPage: itemsPerPage,
      totalPages: Math.ceil(questions.length / itemsPerPage),
    });
  }, [questions, itemsPerPage, page]);

  const getImages = async () => {
    const bankImagesResponse = await getAllImages();
    setBankImages(bankImagesResponse);
    const tempImages = [...images];
    for (let i = 0; i < images.length; i++) {
      if (images[i].details) {
        for (let j = 0; j < bankImagesResponse.length; j++) {
          if (images[i].details === bankImagesResponse[j].details) {
            tempImages[i] = {
              details: bankImagesResponse[j].details,
              file: bankImagesResponse[j].id,
              type: 'bank',
              image_type: null,
            };
            break;
          }
        }
      }
    }
    const tempBankImagesIdToIdx = {};
    bankImagesResponse.forEach((image, idx) => {
      tempBankImagesIdToIdx[image.id] = idx;
    });
    setImages(tempImages);
    setBankImagesIdToIdx(tempBankImagesIdToIdx);
  };

  useEffect(() => {
    if (!deviceFile) {
      for (let i = 0; i < images.length; i++) {
        if (images[i].type === 'file' && images[i].file) {
          setDeviceFile(true);
          break;
        }
      }
    }
  }, [images]);

  useEffect(() => {
    getImages();
  }, [questions]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Pregunta</TableHeader>
            <TableHeader>Tipo de pregunta</TableHeader>
            <TableHeader>Dificultad</TableHeader>
            <TableHeader>Dominio</TableHeader>
            <TableHeader>Puntaje</TableHeader>
            <TableHeader>Respuesta correcta</TableHeader>
            <TableHeader>Ejemplo respuesta correcta</TableHeader>
            <TableHeader>Alt. A</TableHeader>
            <TableHeader>Alt. B</TableHeader>
            <TableHeader>Alt. C</TableHeader>
            <TableHeader>Alt. D</TableHeader>
            <TableHeader>Tiempo (seg.)</TableHeader>
            <TableHeader>Pistas</TableHeader>
            <TableHeader>Explicación pregunta respuestas</TableHeader>
            <TableHeader>Imagen</TableHeader>
            <TableHeader>Mesa</TableHeader>
            <TableHeader>Orden</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions
            .slice(itemsPerPage * (page - 1), itemsPerPage * page)
            .map((question, index) => (
              <TableRow key={index}>
                <TableCell>{question.content}</TableCell>
                <TableCell>{question.question_type}</TableCell>
                <TableCell>{question.difficulty}</TableCell>
                <TableCell>{question.domain}</TableCell>
                <TableCell>{question.total_points}</TableCell>
                <TableCell>{question.correct_answer}</TableCell>
                <TableCell>{question.example_correct_answer}</TableCell>
                <TableCell>{question.possible_answers[0]}</TableCell>
                <TableCell>{question.possible_answers[1]}</TableCell>
                <TableCell>{question.possible_answers[2]}</TableCell>
                <TableCell>{question.possible_answers[3]}</TableCell>
                <TableCell>{question.time_to_answer}</TableCell>
                <TableCell>{question.tips}</TableCell>
                <TableCell>{question.explanation_question_answers}</TableCell>
                <TableCell>
                  <FileUpload
                    label='Seleccionar del dispositivo'
                    onChange={event => {
                      const tempImages = [...images];
                      tempImages[itemsPerPage * (page - 1) + index] = {
                        details: event.target.files[0].name.split('.')[0],
                        file: event.target.files[0],
                        type: 'file',
                        image_type: '2D-Image',
                      };
                      setImages(tempImages);
                    }}
                    accept='image/*'
                    startIcon={<FileUploadOutlinedIcon />}
                  />
                  {deviceFile &&
                  images[itemsPerPage * (page - 1) + index].type === 'file' &&
                  images[itemsPerPage * (page - 1) + index].details ? (
                    <Select
                      label='Tipo de imagen'
                      name='image_type'
                      value={images[itemsPerPage * (page - 1) + index].image_type || ''}
                      options={[
                        { value: '2D-Image', label: 'Imagen 2D' },
                        { value: '3D-Image', label: 'Imagen 3D' },
                      ]}
                      onChange={event => {
                        const tempImages = [...images];
                        tempImages[itemsPerPage * (page - 1) + index] = {
                          ...tempImages[itemsPerPage * (page - 1) + index],
                          image_type: event.target.value,
                        };
                        setImages(tempImages);
                      }}
                      required
                      sx={{ minWidth: '200px', mt: 2 }}
                    />
                  ) : null}
                  {deviceFile ? (
                    <Select
                      label='Seleccionar subida del dispositivo'
                      name='image_id'
                      onChange={event => {
                        const tempImages = [...images];
                        tempImages[itemsPerPage * (page - 1) + index] = {
                          details: images[event.target.value].details,
                          file: event.target.value,
                          type: 'index',
                          image_type: null,
                        };
                        setImages(tempImages);
                      }}
                      value={
                        images[itemsPerPage * (page - 1) + index].type === 'index'
                          ? images[itemsPerPage * (page - 1) + index].file
                          : ''
                      }
                      options={images
                        .map((option, idx) => {
                          if (option.file && option.type === 'file') {
                            return {
                              value: idx,
                              label: option.details,
                            };
                          }
                          return null;
                        })
                        .filter(option => option)}
                      sx={{ minWidth: '300px', mt: 3 }}
                    />
                  ) : null}
                  <Select
                    label='Seleccionar del banco de imágenes'
                    name='image_id'
                    onChange={event => {
                      const tempImages = [...images];
                      tempImages[itemsPerPage * (page - 1) + index] = {
                        details: bankImages[event.target.value].details,
                        file: bankImages[event.target.value].id,
                        type: 'bank',
                        image_type: null,
                        idx: event.target.value,
                      };
                      setImages(tempImages);
                    }}
                    value={
                      Object.keys(bankImagesIdToIdx).length > 0 &&
                      images[itemsPerPage * (page - 1) + index].type === 'bank'
                        ? bankImagesIdToIdx[images[itemsPerPage * (page - 1) + index].file] || ''
                        : ''
                    }
                    options={bankImages.map((option, idx) => ({
                      value: idx,
                      label: option.details,
                    }))}
                    sx={{ minWidth: '300px', my: 3 }}
                  />
                  <Typography variant='body2' color='green'>
                    <b>
                      {images[itemsPerPage * (page - 1) + index].details &&
                      (images[itemsPerPage * (page - 1) + index].file ||
                        images[itemsPerPage * (page - 1) + index].type === 'index')
                        ? `Imagen seleccionada: ${
                            images[itemsPerPage * (page - 1) + index].details
                          }`
                        : 'Ninguna imagen seleccionada'}
                    </b>
                  </Typography>
                </TableCell>
                <TableCell>{question.table}</TableCell>
                <TableCell>{question.order}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        pagination={pagination}
        setPage={setPage}
        setLoading={setLoading}
        setItemPerPage={setItemsPerPage}
      />
    </>
  );
};

export default ImportQuestionsTable;

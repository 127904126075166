import React, { useState } from 'react';

import Form from '../../style-components/Form/Form.jsx';
import Select from '../../style-components/Form/Select.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import { postPushNotification, postPushNotificationToCourse } from '../../models/notifications.js';

const styles = {
  container: {
    width: '100%',
    maxWidth: '100%',
  },
};

function NotificationForm({ users = [], course_id = undefined, setOpen }) {
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    usersIds: [],
    title: '',
    body: '',
    data: {
      screen_url: "notifications", 
      type: "notification"
    },
  });

  const handleSubmit = async () => {
    const { usersIds, title, body, data } = formState;
    let toSend = { title, body };

    try {
      if (!course_id && data !== '') {
        toSend.data = JSON.parse(formState.data);
      }
    } catch (error) {
      alert('El campo data debe ser un JSON válido.');
      return;
    }

    if (!title || !body) {
      alert('El título y el mensaje son campos requeridos.');
      return;
    }

    if (course_id) {
      setLoading(true);
      let response = await postPushNotificationToCourse(course_id, toSend);
      setLoading(false);

      if (response.status === 200 || response.status === 201) {
        alert(`Notificación enviada con éxito.`);
        setOpen(false);
      } else {
        alert(`Ocurrió un error, intente de nuevo.`);
      }
    } else {
      if (usersIds.length === 0) {
        alert('Debe seleccionar al menos un usuario.');
        return;
      }

      toSend.usersIds = usersIds;

      setLoading(true);
      let response = await postPushNotification(toSend);
      setLoading(false);

      if (response.status === 200 || response.status === 201) {
        alert(`Notificación enviada con éxito.`);
        setOpen(false);
      } else {
        alert(`Ocurrió un error, intente de nuevo.`);
      }
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <Form
      title={`Crear notificación`}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      <div style={styles.container}>
        <div>
          <TextField
            label='Título'
            name='title'
            value={formState.title}
            onChange={handleChange}
            required
          />
          <TextField
            label='Mensaje'
            name='body'
            value={formState.body}
            onChange={handleChange}
            multiline={true}
            minRows={4}
            required
          />
          {!course_id && (
            <>
              <TextField
                label='Data JSON'
                name='data'
                placeholder='{"screen_url": "notifications", "type": "notification"}'
                value={typeof formState.data === 'string' ? formState.data : JSON.stringify(formState.data, null, 2)}
                onChange={handleChange}
              />
              <Select
                label='Usuarios'
                name='usersIds'
                value={formState.usersIds}
                onChange={handleChange}
                options={users.map(user => ({
                  value: user.id,
                  label: `${user.firstnames} ${user.lastnames}`,
                }))}
                multiple
                required
              />
            </>
          )}
        </div>
      </div>
    </Form>
  );
}

export default NotificationForm;

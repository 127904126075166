import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import { getCurrentUser } from '../../models/user';

const LandingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '80px',
    },
  };

  useEffect(() => {
    getCurrentUser()
      .then(user => {
        if (user?.role !== 'ADMIN' && user?.role !== 'TEACHER') {
          navigate('/unauthorized');
        }
      })
      .catch();
  }, []);

  return (
    <Container sx={styles.container}>
      <Typography color={theme.palette.primary.main} variant='h5'>
        <b>Bienvenido/a a MedicineHub</b>
      </Typography>
    </Container>
  );
};

export default LandingPage;

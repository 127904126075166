import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthHub from '../components/authHub.jsx';

function PrivateRoute() {
  return (
    <AuthHub>
      <Outlet />
    </AuthHub>
  );
}

export default PrivateRoute;

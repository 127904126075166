import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import TextFieldInput from '../../style-components/Form/TextField.jsx';
import { useGetTerms, patchTerms } from '../../models/terms.js';

const Terms = () => {
  const [terms, termsLoading] = useGetTerms();
  const [termsText, setTermsText] = useState('');

  const saveTerms = async () => {
    const response = await patchTerms({ body: termsText });
    if (response.status === 200) {
      alert('Consentimiento guardado con éxito.');
    } else {
      alert('Ocurrió un error al guardar el consentimiento. Intenta de nuevo.');
    }
  };

  useEffect(() => {
    if (terms && !termsLoading) {
      setTermsText(terms.body);
    }
  }, [terms, termsLoading]);

  return (
    <BoxContainer>
      <div className='flex flex-col gap-6 m-6'>
        <Typography variant='h4'>Consentimiento Informado</Typography>
        <TextFieldInput
          value={termsText}
          onChange={e => setTermsText(e.target.value)}
          multiline
          rows={40}
          fullWidth
          variant='outlined'
        />
        <Button
          style={{ flex: 1, alignSelf: 'center' }}
          variant='contained'
          color='primary'
          onClick={saveTerms}>
          Guardar
        </Button>
      </div>
    </BoxContainer>
  );
};

export default Terms;

import React, { useState, useEffect } from 'react';

import { postCourse, patchCourse } from '../../models/course.js';
import { useGetUniversities } from '../../models/universities.js';
import { getUsersWithPagination, getCurrentUser } from '../../models/user.js';

import Form from '../../style-components/Form/Form.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import Select from '../../style-components/Form/Select.jsx';
import Switch from '../../style-components/Form/Switch.jsx';

const CourseForm = ({ course, method, setOpen }) => {
  const [universities, setUniversities] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('TEACHER');
  const [universitiesData, universitiesDataLoading] = useGetUniversities();

  const [formState, setFormState] = useState({
    name: course?.name || '',
    year: course?.year || '',
    semester: course?.semester || '',
    university_id: course?.university_id || '',
    teacher_id: '',
    for_all: course?.for_all || false,
  });

  useEffect(() => {
    getCurrentUser()
      .then(res => setRole(res.role))
      .catch();
  }, []);

  useEffect(() => {
    getUsersWithPagination(1, 99999, 'TEACHER', formState.university_id).then(res =>
      setTeachers(
        res.users.map(teacher => ({
          value: teacher.id,
          label: `${teacher.firstnames} ${teacher.lastnames}`,
        }))
      )
    );
  }, [formState.university_id]);

  useEffect(() => {
    if (universitiesData && !universitiesDataLoading) {
      setUniversities(
        universitiesData.map(university => ({ value: university.id, label: university.name }))
      );
    }
  }, [universitiesData, universitiesDataLoading]);

  const handleChange = e => {
    if (e.target.name === 'university_id') {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value,
        teacher_id: '',
      });
    } else if (e.target.name === 'for_all') {
      setFormState({
        ...formState,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFormState({
        ...formState,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async () => {
    if (formState.year < new Date().getFullYear()) {
      alert('El año debe ser mayor o igual al actual.');
      return;
    }
    if (method === 'PATCH' || role !== 'ADMIN') {
      delete formState.teacher_id;
    }

    setLoading(true);

    if (method === 'POST') {
      const response = await postCourse(formState);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        alert('Curso añadido con éxito.');
        setOpen(false);
        window.location.reload();
      } else {
        alert('Error al añadir curso. Intente de nuevo.');
      }
    } else if (method === 'PATCH') {
      const response = await patchCourse(course.id, formState);
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        alert('Curso editado con éxito.');
        setOpen(false);
        window.location.reload();
      } else {
        alert('Error al añadir curso. Intente de nuevo.');
      }
    }
  };

  return (
    <Form
      title={method === 'POST' ? 'Añadir Curso' : 'Editar Curso'}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      <TextField
        label='Nombre'
        value={formState.name}
        onChange={handleChange}
        name='name'
        required
        placeholder='Anatomía I'
      />
      <TextField
        label='Año'
        value={formState.year}
        onChange={handleChange}
        name='year'
        required
        type='number'
        placeholder='2024'
      />
      <Select
        name='semester'
        label='Semestre'
        value={formState.semester}
        onChange={handleChange}
        options={[
          { value: 'Primero', label: 'Primero' },
          { value: 'Segundo', label: 'Segundo' },
        ]}
        required
      />
      <Select
        name='university_id'
        label='Universidad'
        value={formState.university_id}
        onChange={handleChange}
        options={universities}
        required
      />
      {method === 'POST' && role === 'ADMIN' && (
        <Select
          name='teacher_id'
          label='Profesor'
          value={formState.teacher_id}
          onChange={handleChange}
          options={teachers}
          required
        />
      )}
      {role === 'ADMIN' && (
        <Switch
          name='for_all'
          label='¿Es el curso transversal (para todos los estudiantes de la aplicación)?'
          value={formState.for_all}
          onChange={handleChange}
        />
      )}
    </Form>
  );
};

export default CourseForm;

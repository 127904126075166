import React from 'react';
import { useParams } from 'react-router-dom';

import BibliographyTable from '../../style-components/Tables/BibliographyTable.jsx';
import BoxContainer from '../../style-components/BoxContainer.jsx';
import { useGetCourse } from '../../models/course.js';
import { useGetLearnunits } from '../../models/learnunits.js';

function BibliographyList() {
  const { course_id, unit_id, course_learnunit_id } = useParams();
  const [course] = useGetCourse(course_id);
  const [learnunits] = useGetLearnunits();
  const learnunit = learnunits?.data?.find(unit => unit.id === parseInt(unit_id));

  return (
    <BoxContainer>
      <BibliographyTable
        courseLearnunitId={course_learnunit_id}
        courseId={course_id}
        courseName={course?.name}
        courseLearnunitName={learnunit?.name}
      />
    </BoxContainer>
  );
}

export default BibliographyList;

import React from 'react';

import CourseForm from './CourseForm.jsx';
import { useGetCourse } from '../../models/course';
import Loading from '../../style-components/Loading.jsx';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};

const EditCourse = ({ id, setOpen }) => {
  const [course, loading, error] = useGetCourse(id);

  return (
    <div style={styles.container}>
      {loading && !error ? (
        <Loading />
      ) : (
        <CourseForm course={course} method={'PATCH'} setOpen={setOpen} />
      )}
    </div>
  );
};

export default EditCourse;

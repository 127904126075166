import React from 'react';
import Form from 'react-bootstrap/Form';
import * as Sentry from '@sentry/react';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import HeaderContainer from '../../style-components/HeaderContainer.jsx';
import { postLaminariaSection } from '../../models/laminaria.js';

const Laminaria = () => {
  // const [sections, setSections] = useState([]);

  // const addSection = () => {
  //   const newSection = {
  //     id: new Date().getTime(),
  //     name: '',
  //   };
  //   setSections([...sections, newSection]);
  // };

  // const handleNameChange = (id, newName) => {
  //   const updatedSections = sections.map(section => {
  //     if (section.id === id) {
  //       return { ...section, name: newName };
  //     }
  //     return section;
  //   });
  //   setSections(updatedSections);
  // };

  const handleFile = async file => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        const parsedData = JSON.parse(reader.result);
        postLaminariaSection(parsedData);
      };
      reader.readAsText(file);
    } catch (err) {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      alert('Error al leer el archivo, intente de nuevo.');
    }
  };

  return (
    <BoxContainer>
      <div className='antialiased font-sans w-full'>
        <div className='divide-y divide-primary'>
          <HeaderContainer title='Laminario' />
          <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
            <Form.Group controlId='formFile' className='mb-3 m-10'>
              <Form.Label>Subir JSON de una sección del laminario</Form.Label>
              <Form.Control
                type='file'
                onChange={event => handleFile(event.target.files[0])}
                accept='.json'
              />
            </Form.Group>
            {/* <button onClick={addSection}>Add Section</button>
        {sections.map(section => (
          <div key={section.id}>
            <h3>Section {section.id}</h3>
            <form>
              <label>
                Name:
                <input
                  type='text'
                  value={section.name}
                  onChange={e => handleNameChange(section.id, e.target.value)}
                />
              </label>
            </form>
          </div>
        ))} */}
          </div>
        </div>
      </div>
    </BoxContainer>
  );
};

export default Laminaria;

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useTheme from '@mui/material/styles/useTheme';

export default function Form({ title, onSubmit, onClose, loading, children }) {
  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      '& .MuiTextField-root': {
        m: 1,
        width: '100%',
      },
    },
    scrollableContent: {
      flex: 1, 
      overflowY: 'auto',
      overflowX: 'hidden', 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      '& .MuiTextField-root': {
        m: 1,
        width: '100%',
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2),
      width: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxSizing: 'border-box',
    },
    footer: {
      padding: theme.spacing(2),
      width: '100%',
      textAlign: 'center',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  };

  return (
    <Box
      component='form'
      sx={styles.container}
      autoComplete='off'
      onSubmit={e => {
        e.preventDefault(); // Prevent the default form submission behavior
        onSubmit(e); // Call the onSubmit function passed as a prop
      }}
      autoSave='false'>
      <Box sx={styles.header}>
        <Typography variant='h4' color={theme.palette.primary.main}>
          {title}
        </Typography>
        <IconButton onClick={onClose} color="inherit">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={styles.scrollableContent}>
        {children}
      </Box>
      <Box sx={styles.footer}>
        <Button type='submit' variant='contained' color='primary' disabled={loading}>
          Aceptar
        </Button>
      </Box>
    </Box>
  );
}

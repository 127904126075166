import { getIdToken } from '../utils/cognitoAuth';

const authorizationHeader = async () => {
  const token = await getIdToken();
  const authorizationHeader = {
    Authorization: 'Bearer ' + token,
  };
  return authorizationHeader;
};

export default authorizationHeader;

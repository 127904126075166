import React from 'react';
import Typography from '@mui/material/Typography';

import HeaderContainer from '../HeaderContainer.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from '../../components/Custom-UI/Tables/Elements.jsx';

const EvaluationRankingTable = ({ course, learningUnit, evaluation, ranking }) => {
  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      { name: 'Unidades de aprendizaje', href: `/courses/${course?.id}/learning_units` },
      {
        name: 'Evaluaciones',
        href: `/courses/${course?.id}/${learningUnit?.id}/evaluations`,
      },
      {
        name: `Ranking de la evaluación ${evaluation?.name} del curso ${course?.name} de la unidad ${learningUnit?.name}`,
        href: '',
      },
    ],
    maxItems: 4,
  };

  return (
    <div className='antialiased font-sans w-full'>
      <div className='divide-y divide-primary'>
        <HeaderContainer breadcrumb={itemsBreadcrumb} actions={[{}]} />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <div className='flex justify-between items-start mb-8'>
            <div className='flex flex-col items-start gap-4'>
              <Typography variant='h6' component='h1'>
                Intentos: {ranking.length}
              </Typography>
              <Typography variant='h6' component='h1'>
                Intentos terminados: {ranking.filter(rank => rank.time).length}
              </Typography>
            </div>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>Nombre</TableHeader>
                <TableHeader>Tiempo (s)</TableHeader>
                <TableHeader>Puntos</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {ranking.map(rank => (
                <TableRow key={`${rank.name}${rank.time}${rank.total_points}`}>
                  <TableCell>{rank.name}</TableCell>
                  <TableCell>{parseInt(rank.time / 1000, 10)}</TableCell>
                  <TableCell>{rank.total_points}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default EvaluationRankingTable;

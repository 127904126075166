import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useTheme from '@mui/material/styles/useTheme';

function BasicModal({ open, setOpen, children }) {
  const theme = useTheme();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: theme.palette.white.main,
    borderRadius: 2,
    p: 2,
    height: '95vh',
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    minWidth: 600,
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>{children}</Box>
      </Modal>
    </div>
  );
}

export default BasicModal;

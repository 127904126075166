import React from 'react';
import { useNavigate } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';

const NavBar = ({ menu }) => {
  const navigation = useNavigate();
  const theme = useTheme();

  const styles = {
    container: {
      backgroundColor: theme.palette.white.main,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRightWidth: '1.5px',
      borderBottomWidth: '1.5px',
      borderColor: theme.palette.primary.main,
      borderBottomRightRadius: '10px',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    button: {
      '&:hover': { color: theme.palette.secondary.main },
    },
  };

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: '10px',
    margin: '8px',
    marginRight: '14px',
    marginLeft: '14px',
    borderRadius: '10px',
    '&:hover': {
      '& .MuiTypography-root': {
        color: theme.palette.secondary.main,
      },
    },
  }));

  const path = window.location.pathname;

  return (
    <div style={styles.container}>
      {menu.map((item, idx) => (
        <StyledIconButton
          key={idx}
          onClick={() => navigation(item.to)}
          color='primary'
          size='large'
          sx={{
            ...styles.button,
            color: path.includes(item.to) ? theme.palette.white.main : theme.palette.primary.main,
            backgroundColor: path.includes(item.to) ? theme.palette.secondary.main : '',
          }}>
          <div className='flex flex-col items-center'>
            {item.icon}
            <Typography
              color={path.includes(item.to) ? theme.palette.white.main : theme.palette.primary.main}
              variant='caption'>
              {item.title}
            </Typography>
          </div>
        </StyledIconButton>
      ))}
    </div>
  );
};

export default NavBar;

import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// GET learning units
export function useGetLearnunits() {
  const { value, loading, error } = useAsync(() => getLearnunits());
  return [value, loading, error];
}

export const getLearnunits = async () => {
  const token = await authorizationHeader();
  const learningUnits = await api
    .get(`/learnunits/`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return learningUnits;
};

// Post new Learnunit
export const postLearnunit = async ua => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/learnunits/new`, ua, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// GET learning unit by course_id
export function useGetCourseLearningUnits(course_id) {
  const { value, loading, error } = useAsync(() => getCourseLearningUnits(course_id), [course_id]);
  return [value, loading, error];
}

const getCourseLearningUnits = async course_id => {
  const token = await authorizationHeader();
  const learningUnits = await api
    .get(`course-learnunits/${course_id}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 404) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return learningUnits;
};

export const deleteCourseLearunit = async id => {
  const token = await authorizationHeader();
  const learningUnits = await api
    .delete(`course-learnunits/${id}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 400) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return learningUnits;
};

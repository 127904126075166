import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import FormHelperText from '@mui/material/FormHelperText';

function Password({ label = '', name, value, onChange, required = false, helperText = '' }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl sx={{ m: 1, width: '100%' }} variant='outlined'>
      <InputLabel htmlFor='password'>
        {label}
        {required ? ' *' : null}
      </InputLabel>
      <OutlinedInput
        id='password'
        name={name}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              edge='end'>
              {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </IconButton>
          </InputAdornment>
        }
        required={required}
        label={label}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default Password;

import React from 'react';

import CourseForm from './CourseForm.jsx';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
};

const NewCourse = ({ setOpen }) => {
  return (
    <div style={styles.container}>
      <CourseForm method={'POST'} setOpen={setOpen} />
    </div>
  );
};

export default NewCourse;

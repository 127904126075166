import { Amplify, Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    region: process.env.REACT_APP_REGION,
    oauth: {
      domain: process.env.REACT_APP_USER_POOL_URI,
      scope: ['openid', 'email', 'profile', 'phone', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_CALLBACK_URI,
      redirectSignOut: process.env.REACT_APP_SIGNOUT_URI,
      responseType: 'code',
    },
    // storage: CustomChromeStorage
  },
});

async function signUp(email, password) {
  return await Auth.signUp({
    username: email,
    password,
  });
}

async function signIn(email, password) {
  return await Auth.signIn(email, password);
}

async function confirmSignUp(email, code) {
  return await Auth.confirmSignUp(email, code);
}
async function resendConfirmationCode(username) {
  return await Auth.resendSignUp(username);
}

async function signOut(global = false) {
  return await Auth.signOut({ global });
}

async function forgotPassword(email) {
  return await Auth.forgotPassword(email);
}

async function forgotPasswordSubmit(email, code, newPassword) {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
    return 'La contraseña fue cambiada correctamente';
  } catch (err) {
    Sentry.captureException(err);
    if (err?.response?.data) {
      Sentry.captureMessage(err.response.data);
    }
    throw err;
  }
}

async function changePassword(oldPassword, newPassword) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    return 'La contraseña fue cambiada correctamente';
  } catch (err) {
    Sentry.captureException(err);
    if (err?.response?.data) {
      Sentry.captureMessage(err.response.data);
    }
    throw err;
  }
}

function getIdToken() {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(data => {
        const idToken = data.getIdToken();
        resolve(idToken.jwtToken);
      })
      .catch(() => {
        reject(Error('No ha iniciado sesión'));
      });
  });
}

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(data => {
        const idToken = data.getIdToken();
        const user = idToken.payload;
        resolve(user);
      })
      .catch(() => {
        reject(Error('No ha iniciado sesión'));
      });
  });
}

export {
  signUp,
  signIn,
  confirmSignUp,
  resendConfirmationCode,
  signOut,
  forgotPassword,
  forgotPasswordSubmit,
  getIdToken,
  changePassword,
  getCurrentUser,
};

import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// GET all bibliographies
export function useGetBibliographies(page, limit) {
  const { value, loading, error } = useAsync(() => getBibliographies(page, limit), [page, limit]);
  return [value, loading, error];
}

export const getBibliographies = async (page, limit) => {
  const token = await authorizationHeader();
  const bibliography = await api
    .get(`/bibliography?page=${page}&limit=${limit}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return bibliography.data;
};

export const getAllBiblio = async () => {
  const data = await getBibliographies(1, 12);
  const pages = data?.pagination.pages;
  let bibliographies = [];
  for (let i = 1; i < pages + 1; i++) {
    const data = await getBibliographies(i, 12);
    for (let j = 0; j < 12; j++) {
      if (data?.bibliographies[j]) {
        bibliographies.push(data?.bibliographies[j]);
      }
    }
  }
  return bibliographies;
};

// GET bibliography by id
export function useGetBibliography(bibliographyId) {
  const { value, loading, error } = useAsync(
    () => getBibliography(bibliographyId),
    [bibliographyId]
  );
  return [value, loading, error];
}

const getBibliography = async bibliographyId => {
  const token = await authorizationHeader();
  const bibliography = await api
    .get(`/bibliography/${bibliographyId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return bibliography.data;
};

// POST bibliography
export const postBibliography = async (bibliography, course_learnunit_id) => {
  const token = await authorizationHeader();
  const response = await api
    .post(
      course_learnunit_id
        ? `/bibliography/new?course_learnunit_id=${course_learnunit_id}`
        : `/bibliography/new`,
      bibliography,
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// DELETE bibliography
export const deleteBibliography = async bibliographyId => {
  const token = await authorizationHeader();
  const response = await api
    .delete(`/bibliography/delete/${bibliographyId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// PATCH bibliography
export const patchBibliography = async (bibliographyId, bibliography) => {
  const token = await authorizationHeader();
  const response = await api
    .patch(`/bibliography/update/${bibliographyId}`, bibliography, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

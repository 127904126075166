import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ArtTrackOutlinedIcon from '@mui/icons-material/ArtTrackOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';

export const sideMenuAdmin = [
  {
    title: 'Cursos',
    to: '/courses',
    icon: <SchoolOutlinedIcon />,
  },
  {
    title: 'Preguntas',
    to: '/questions',
    icon: <HelpOutlineOutlinedIcon />,
  },
  {
    title: 'Imágenes',
    to: '/images',
    icon: <PhotoLibraryOutlinedIcon />,
  },
  {
    title: 'Usuarios',
    to: '/users',
    icon: <GroupsOutlinedIcon />,
  },
  {
    title: 'Bibliografías',
    to: '/bibliographies',
    icon: <LibraryBooksOutlinedIcon />,
  },
  {
    title: 'Universidades',
    to: '/universities',
    icon: <AccountBalanceOutlinedIcon />,
  },
  {
    title: 'Chats',
    to: '/chats',
    icon: <ChatOutlinedIcon />,
  },
  {
    title: 'Laminario',
    to: '/laminaria',
    icon: <ArtTrackOutlinedIcon />,
  },
  {
    title: 'Términos',
    to: '/terms',
    icon: <GavelOutlinedIcon />,
  },
];

export const sideMenuTeacher = [
  {
    title: 'Mis cursos',
    to: '/courses',
    icon: <SchoolOutlinedIcon />,
  },
  {
    title: 'Banco de preguntas',
    to: '/questions',
    icon: <HelpOutlineOutlinedIcon />,
  },
  {
    title: 'Banco de imágenes',
    to: '/images',
    icon: <PhotoLibraryOutlinedIcon />,
  },
  {
    title: 'Mis estudiantes',
    to: '/students',
    icon: <GroupsOutlinedIcon />,
  },
  {
    title: 'Chats de mis estudiantes',
    to: '/chats',
    icon: <ChatOutlinedIcon />,
  },
];

import React from 'react';
import { useParams } from 'react-router-dom';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import Loading from '../../style-components/Loading.jsx';
import EvaluationsTable from '../../style-components/Tables/EvaluationsTable.jsx';
import { useGetCourse } from '../../models/course.js';
import { useGetLearnunits } from '../../models/learnunits.js';

function TeacherEvaluations() {
  const { id, unit_id } = useParams();
  const [course, courseLoading, courseError] = useGetCourse(id);
  const [learnunits] = useGetLearnunits();
  const learnunit = learnunits?.data?.find(unit => unit.id === parseInt(unit_id));

  return (
    <BoxContainer>
      {courseLoading && !courseError ? (
        <Loading />
      ) : (
        <EvaluationsTable course={course} courseLearnunitName={learnunit?.name} />
      )}
    </BoxContainer>
  );
}

export default TeacherEvaluations;

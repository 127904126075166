import React, { useState, useEffect } from 'react';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import Loading from '../../style-components/Loading.jsx';
import CourseLearnunitTable from '../../style-components/Tables/CourseLearnunitTable.jsx';
import { useGetCourseLearningUnits, useGetLearnunits } from '../../models/learnunits.js';

function LearningUnitList({ course }) {
  const [courseLearnunits, setCourseLearnunits] = useState([]);
  const [learnunitsNames, setLearnunitsNames] = useState({});
  const [learnunitsNamesData, learnunitsNamesDataLoading] = useGetLearnunits();
  const [courseLearnunitsData, courseLearnunitsDataLoading] = useGetCourseLearningUnits(course.id);
  const [courseLearunitsWithName, setCourseLearunitsWithName] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (courseLearnunitsData && !courseLearnunitsDataLoading) {
      setCourseLearnunits(courseLearnunitsData?.data || []);
    }
  }, [courseLearnunitsData, courseLearnunitsDataLoading]);

  useEffect(() => {
    if (learnunitsNamesData && !learnunitsNamesDataLoading) {
      const tempLearnunitsNames = {};
      for (let i = 0; i < learnunitsNamesData.data.length; i++) {
        tempLearnunitsNames[learnunitsNamesData.data[i].id] = learnunitsNamesData.data[i].name;
      }
      setLearnunitsNames(tempLearnunitsNames);
    }
  }, [learnunitsNamesData, learnunitsNamesDataLoading]);

  useEffect(() => {
    if (
      !learnunitsNamesDataLoading &&
      !courseLearnunitsDataLoading &&
      courseLearnunits &&
      learnunitsNames
    ) {
      const tempCourseLearunitsWithName = courseLearnunits.map(courseLearnunit => {
        return {
          ...courseLearnunit,
          name: learnunitsNames[courseLearnunit.learnunit_id],
        };
      });
      setCourseLearunitsWithName(tempCourseLearunitsWithName);
    }
  }, [courseLearnunits, learnunitsNames]);

  useEffect(() => {
    if (!learnunitsNamesDataLoading && !courseLearnunitsDataLoading && courseLearunitsWithName) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [learnunitsNamesDataLoading, courseLearnunitsDataLoading, courseLearunitsWithName]);

  return (
    <BoxContainer>
      {loading ? (
        <Loading />
      ) : (
        <CourseLearnunitTable courseLearnunits={courseLearunitsWithName} course={course} />
      )}
    </BoxContainer>
  );
}

export default LearningUnitList;

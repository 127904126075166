import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

//POST university
export const postUniversity = async university => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/universities/new`, university, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

//GET all universities
export function useGetUniversities() {
  const { value, loading, error } = useAsync(() => getUniversities());
  return [value, loading, error];
}

const getUniversities = async () => {
  const token = await authorizationHeader();
  const universities = await api
    .get(`/universities/`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return universities.data;
};

//GET university by id
export function useGetUniversity(universityId) {
  const { value, loading, error } = useAsync(() => getUniversity(universityId), [universityId]);
  return [value, loading, error];
}

export const getUniversity = async universityId => {
  const token = await authorizationHeader();
  const universities = await api
    .get(`/universities/${universityId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return universities.data;
};

// DELETE university
export const deleteUniversity = async universityId => {
  const token = await authorizationHeader();
  const universities = await api
    .delete(`/universities/delete/${universityId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return universities;
};

// PATCH university
export const patchUniversity = async (universityId, university) => {
  const token = await authorizationHeader();
  const universities = await api
    .patch(`/universities/update/${universityId}`, university, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return universities;
};

import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// POST laminaria section
export const postLaminariaSection = async section => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/laminaria`, section, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

import { useAsync } from 'react-use';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';
import { signUp } from '../utils/cognitoAuth';

// GET all users
export function useGetUsers() {
  const { value, loading, error } = useAsync(() => getUsers());
  return [value, loading, error];
}

export function useGetUsersWithPagination(page, limit, roleFilter, universityFilter, searchFilter) {
  const { value, loading, error } = useAsync(
    () => getUsersWithPagination(page, limit, roleFilter, universityFilter, searchFilter),
    [page, limit, roleFilter, universityFilter, searchFilter]
  );
  return [value, loading, error];
}

export const getUsersWithPagination = async (
  page,
  limit,
  roleFilter = '',
  universityFilter = '',
  searchFilter=''
) => {
  const token = await authorizationHeader();
  const users = await api
    .get(
      `/users?page=${page}&limit=${limit}&roleFilter=${roleFilter}&universityFilter=${universityFilter}&searchFilter=${searchFilter}`,
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return users.data;
};

export const getUsers = async () => {
  const token = await authorizationHeader();
  const users = await api
    .get(`/users`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return users.data;
};

// GET user by id
export function useGetUser(userId) {
  const { value, loading, error } = useAsync(() => getUser(userId), [userId]);
  return [value, loading, error];
}

const getUser = async userId => {
  const token = await authorizationHeader();
  const user = await api
    .get(`/users/${userId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return user.data;
};

export const getUserDataByEmail = async () => {
  let control = 0;
  let userData;
  const user = await Auth.currentUserInfo();
  const data = await getUsers();
  const total_pages = parseInt(data?.pagination?.totalPages);

  for (let i = 1; i < total_pages + 1; i++) {
    const data = await getUsersWithPagination(i, 10);
    const users = data.users;
    for (let i = 0; i < users.length; i++) {
      if (users[i].email === user['attributes'].email) {
        control = 1;
        userData = users[i];
      }
    }
    if (control === 1) {
      return userData;
    }
  }
};

// POST user
export const postUser = async user => {
  const token = await authorizationHeader();
  // borramos la password del for para no enviarla al back
  const password = user.password;
  user.password = ' ';
  const response = await api
    .post(`/users/new/course/0`, user, {
      headers: token,
    })
    .then(res => {
      if (res.data[1] === true) {
        signUp(user.email, password).catch(err => {
          Sentry.captureException(err);
          deleteUser(res.data[0].id).catch(error => Sentry.captureException(error));
          return err;
        });
      }
      return res;
    })
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// DELETE user
export const deleteUser = async userId => {
  const token = await authorizationHeader();
  const response = await api
    .delete(`/users/delete/${userId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// PATCH user
export const patchUser = async (user, id) => {
  const token = await authorizationHeader();
  const response = await api
    .patch(`/users/update/${id}`, user, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// GET students lists
export function useGetStudentsLists(page, limit) {
  const { value, loading, error } = useAsync(() => getStudentsLists(page, limit), [page, limit]);
  return [value, loading, error];
}

export const getStudentsLists = async (page, limit) => {
  const token = await authorizationHeader();
  const studentsLists = await api
    .get(`/kpi/users/list?page=${page}&limit=${limit}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });

  return studentsLists.data;
};

// GET students of a Course
export function useGetStudentsOfCourse(page, limit, courseId) {
  const { value, loading, error } = useAsync(
    () => getStudentsOfCourse(page, limit, courseId),
    [page, limit, courseId]
  );
  return [value, loading, error];
}

export const getStudentsOfCourse = async (page, limit, courseId) => {
  if (!courseId) {
    return {};
  }
  const token = await authorizationHeader();
  const students = await api
    .get(`/users/course/${courseId}?page=${page}&limit=${limit}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });

  return students.data;
};

// DELETE students from a Course
export const deleteUserFromCourse = async (userId, courseId) => {
  const token = await authorizationHeader();
  const response = await api
    .delete(`/users/delete/course/${courseId}/user/${userId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// GET current user
export function useGetCurrentUser() {
  const { value, loading, error } = useAsync(() => getCurrentUser());
  return [value, loading, error];
}

export const getCurrentUser = async () => {
  const token = await authorizationHeader();
  const user = await api
    .get(`/currentUser`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return user.data;
};

// POST users
export const postUsers = async (users, course_id) => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/users/new_bulk/course/${course_id}`, users, {
      headers: token,
    })
    .then(async res => {
      const usersRes = res.data;
      const usersCognito = usersRes.map(([user, created]) => {
        if (!user || !created) {
          return null;
        }
        if (created) {
          const password = `${process.env.REACT_APP_PASS}.${user.student_id}`;
          return signUp(user.email, password).catch(err => {
            if (err.code !== 'UsernameExistsException') {
              deleteUser(user.id).catch(error => Sentry.captureException(error));
              return { userConfirmed: false };
            }
            Sentry.captureException(err);
            return { userConfirmed: true };
          });
        } else {
          return { userConfirmed: true };
        }
      });
      const promises = await Promise.all(usersCognito);
      return promises;
    })
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

export const patchUserMobileOrVR = async (id, value) => {
  const token = await authorizationHeader();
  const response = await api
    .patch(
      `/users/update/mobileOrVR/${id}`,
      { mobileOrVR: value },
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

export const swapUserMobileOrVR = async id => {
  const token = await authorizationHeader();
  const response = await api
    .patch(
      `/users/update/mobileOrVR/swap/course/${id}`,
      {},
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

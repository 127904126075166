import React, { useState, useEffect } from 'react';
import ModalImage from 'react-modal-image';

import { getImage } from '../models/images';

export default function ImageComponent({ image_id }) {
  const [toReturn, setToReturn] = useState(<p>Cargando...</p>);
  useEffect(() => {
    getImage(image_id).then(image =>
      setToReturn(
        <ModalImage
          className='w-80'
          small={image.url}
          large={image.url}
          alt={image.details}
          hideZoom
          hideDownload
        />
      )
    );
  }, [image_id]);
  return toReturn;
}

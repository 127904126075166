import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import { changePassword } from '../../utils/cognitoAuth';

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  function handleChangePassword(e) {
    e.preventDefault();
    changePassword(oldPassword, newPassword).catch(err => Sentry.captureException(err));
  }
  return (
    <>
      <h3>Cambiar Contraseña</h3>
      <form onSubmit={handleChangePassword}>
        <input
          required
          placeholder='Antigua contraseña'
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
        />
        <input
          required
          placeholder='Nueva contraseña'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <button>Aceptar</button>
      </form>
    </>
  );
}

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';

const Pagination = ({ pagination, setPage, setLoading, setItemPerPage }) => {
  const { totalPages, currentPage, itemsPerPage } = pagination;

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1).filter(
    p => p >= currentPage - 2 && p <= currentPage + 2 && p >= 1 && p <= totalPages
  );

  function handlePageChange(page) {
    if (page < 1 || page > totalPages) return;
    setPage(page);
    setLoading(true);
  }

  function handleLimitChange(limit) {
    setItemPerPage(limit);
    setPage(1);
    setLoading(true);
  }

  return (
    <div>
      <div
        className={
          'grid grid-cols-1 bg-white text-primary border-t border-white/50 py-4 px-4 rounded-b-lg md:grid-cols-3 md:gap-4'
        }>
        <div className='flex items-center justify-center gap-x-2'>
          <span className=''>Mostrando</span>
          <select
            className='border border-gray-300 rounded-md text-sm focus:outline-none px-2 text-center max-w-20'
            value={itemsPerPage}
            onChange={e => handleLimitChange(Number(e.target.value))}>
            {[1, 5, 10, 20, 100, 2000].map(l => (
              <option key={l} value={l}>
                {l}
              </option>
            ))}
          </select>
          <span className=''>por página</span>
        </div>
        <div className='flex items-center justify-center gap-x-4 md:col-span-2 max-w-xl w-full mx-auto'>
          <button
            className='w-8 h-8 rounded-full transition-colors hover:text-white hover:bg-primary/75 justify-center flex items-center'
            onClick={() => handlePageChange(1)}>
            <KeyboardDoubleArrowLeftOutlinedIcon />
          </button>
          <button
            className='w-8 h-8 rounded-full transition-colors hover:text-white hover:bg-primary/75 justify-center flex items-center gap-x-1'
            onClick={() => handlePageChange(currentPage - 1)}>
            <KeyboardArrowLeftIcon />
          </button>
          <div className='flex items-center justify-center gap-x-2'>
            {pages.map(p => (
              <button
                key={p}
                className={`w-8 h-8 rounded-full transition-colors  ${
                  p === currentPage
                    ? 'text-center text-white hover:text-white font-semibold bg-primary hover:bg-primary/75'
                    : 'hover:bg-secondary hover:text-white '
                }`}
                onClick={() => handlePageChange(p)}>
                {p}
              </button>
            ))}
          </div>
          <button
            className='w-8 h-8 rounded-full transition-colors hover:text-white hover:bg-primary/75 justify-center flex items-center'
            onClick={() => handlePageChange(currentPage + 1)}>
            <KeyboardArrowRightIcon />
          </button>
          <button
            className='w-8 h-8 rounded-full transition-colors hover:text-white hover:bg-primary/75 justify-center flex items-center'
            onClick={() => handlePageChange(totalPages)}>
            <KeyboardDoubleArrowRightOutlinedIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;

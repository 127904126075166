import React, { useEffect, useState } from 'react';
import ModalImage from 'react-modal-image';
import { getImage } from '../../../models/images';
import Logo from '../../../assets/images/blue_logo.png';
import Skeleton from '@mui/material/Skeleton';

function ImageThumbnail({ image_id, image_name, show_name }) {
  const [loadingImage, setLoadingImage] = useState(true);
  const [name, setName] = useState('');
  const [thumbnail, setThumbnail] = useState();

  const styles = {
    textContainer: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      fontSize: '16px',
      padding: '10px',
      textAlign: 'center',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
    },
    defaultImage: {
      height: '150px',
      width: '100%',
      borderRadius: '20px',
      objectFit: 'cover',
    },
  };

  const fetchImageUrl = async () => {
    setLoadingImage(true);
    if (image_id) {
      const img = await getImage(image_id);
      setThumbnail(img.url);
      setName(img.details);
    }
    setLoadingImage(false);
  };

  useEffect(() => {
    fetchImageUrl();
  }, [image_id]);

  return (
    <div className='text-center p-2'>
      {loadingImage ? (
        <Skeleton sx={{ bgcolor: 'grey.600' }} variant='rounded' width={280} height={118} />
      ) : (
        <div className='relative'>
          {thumbnail ? (
            <ModalImage
              small={thumbnail}
              large={thumbnail}
              alt={image_name || name}
              hideDownload
              hideZoom
              className='rounded-3xl h-36 w-full object-cover'
            />
          ) : (
            <img src={Logo} alt='logo' style={styles.defaultImage} />
          )}
          {show_name && <div style={styles.textContainer}>{image_name || name}</div>}
        </div>
      )}
    </div>
  );
}

export default ImageThumbnail;

import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';

const mobileOrVR = val => {
  if (val === null) {
    return 'AMBAS';
  } else if (val === false) {
    return 'MOVIL';
  } else if (val === true) {
    return 'VR';
  }
  return '';
};

const ImportStudentsTable = ({ students }) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: Math.ceil(students.length / itemsPerPage),
  });
  const [, setLoading] = useState(false);

  useEffect(() => {
    setPagination({
      currentPage: page,
      itemsPerPage: itemsPerPage,
      totalPages: Math.ceil(students.length / itemsPerPage),
    });
  }, [students, itemsPerPage, page]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Correo</TableHeader>
            <TableHeader>ID estudiante</TableHeader>
            <TableHeader>Nombre/s</TableHeader>
            <TableHeader>Apellido/s</TableHeader>
            <TableHeader>Fecha de nacimiento</TableHeader>
            <TableHeader>Género</TableHeader>
            <TableHeader>País</TableHeader>
            <TableHeader>Provincia/Comuna</TableHeader>
            <TableHeader>Tipo de establecimiento</TableHeader>
            <TableHeader>Vía de entrada</TableHeader>
            <TableHeader>Veces que ha cursado el curso</TableHeader>
            <TableHeader>Carrera previa</TableHeader>
            <TableHeader>Habilitación aplicaciones</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.slice(itemsPerPage * (page - 1), itemsPerPage * page).map((student, index) => (
            <TableRow key={index}>
              <TableCell>{student.email}</TableCell>
              <TableCell>{student.student_id}</TableCell>
              <TableCell>{student.firstnames}</TableCell>
              <TableCell>{student.lastnames}</TableCell>
              <TableCell>{moment(student.birthday)?.utc()?.format('DD/MM/YYYY')}</TableCell>
              <TableCell>{student.gender}</TableCell>
              <TableCell>{student.country}</TableCell>
              <TableCell>{student.province}</TableCell>
              <TableCell>{student.establishment}</TableCell>
              <TableCell>{student.entry_way}</TableCell>
              <TableCell>{student.times_course_taken}</TableCell>
              <TableCell>{student.previous_career}</TableCell>
              <TableCell>{mobileOrVR(student.mobileOrVR)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        pagination={pagination}
        setPage={setPage}
        setLoading={setLoading}
        setItemPerPage={setItemsPerPage}
      />
    </>
  );
};

export default ImportStudentsTable;

import React, { useState } from 'react';
import { cn } from '../../../utils/cn';
import SortMenu from './SortMenu.jsx';

export function Table({ children, className, ...props }) {
  return (
    <div className={cn('border rounded-t-lg overflow-x-auto', className)}>
      <table
        className={'min-w-full caption-bottom table-fixed text-sm text-left bg-white text-primary'}
        {...props}>
        {children}
      </table>
    </div>
  );
}

export function TableHead({ children, className, ...props }) {
  return (
    <thead
      className={cn('font-medium border-b border-primary uppercase divide-y bg-white', className)}
      {...props}>
      {children}
    </thead>
  );
}

export function TableHeader({ children, className, sortable, filterable, onSort, onFilter, ...props }) {
  const [sortDirection, setSortDirection] = useState(null);

  const handleSort = (direction) => {
    setSortDirection(direction);
    onSort(direction);
  };

  return (
    <th className={cn('px-6 py-3 tracking-wider h-[4rem] relative', className)} {...props}>
      <div className="flex items-center justify-start">
        {children}
        {/* {sortable && (
          <SortMenu onSelect={handleSort} />
        )} */}
        <div className="flex space-x-2">
          {sortable && <SortMenu onSelect={handleSort} />}
          {filterable && <SortMenu onFilter={onFilter} />}
        </div>
      </div>
    </th>
  );
}

export function TableBody({ children, className, ...props }) {
  return (
    <tbody className={cn('h-[50px] divide-y bg-white divide-primary/50', className)} {...props}>
      {children}
    </tbody>
  );
}

export function TableRow({ children, ...props }) {
  return <tr {...props}>{children}</tr>;
}

export function TableCell({ children, className, type, ...props }) {
  return (
    <td
      className={cn(
        'px-6 py-3 font-normal text-sm ',
        type === 'actions' && 'space-x-4 text-right',
        type === 'date' && 'font-mono font-semibold',
        type === 'name' && 'text-sm font-normal',
        type === 'number' && 'font-semibold',
        type === 'id' && 'text-xs font-mono',
        type === 'email' && 'font-mono',
        type === 'description' && 'font-mono whitespace-normal',
        type === 'error' && 'font-bold text-red-600',
        type === 'success' && 'font-bold text-green-600',
        className
      )}
      {...props}>
      {children}
    </td>
  );
}

export function CellName({ name, firstLabel, firstField, secondLabel, secondField }) {
  return (
    <div className='grid grid-rows-1 gap-1'>
      { name && <p className='font-bold text-base'>{name}</p> }
      <div>
        <p>
          {firstLabel}: <b>{firstField}</b>
        </p>
        <p>
          {secondLabel}: <b>{secondField}</b>
        </p>
      </div>
    </div>
  );
}

export function TableButton({ children, className, variant, ...props }) {
  return (
    <button
      type='button'
      className={cn(
        'font-semibold text-sm py-0 px-4 rounded-r duration-150 text-blue-300 hover:text-blue-200 disabled:text-gray-400 disabled:hover:text-gray-500',
        variant === 'edit' && 'text-gray-500 hover:text-gray-800 hover:bg-gray-200',
        variant === 'create' && 'text-green-500 hover:text-green-600 hover:bg-green-200',
        variant === 'delete' && 'text-red-500 hover:text-red-600 hover:bg-red-200',
        variant === 'text' &&
          'text-white hover:text-white hover:bg-secondary/75 bg-secondary rounded-full px-4 py-0 text-xs',
        className
      )}
      {...props}>
      {children}
    </button>
  );
}

import * as React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function FormSwitch({ name, label = '', value, onChange }) {
  return (
    <FormControlLabel
      control={<Switch color='primary' name={name} checked={value} onChange={onChange} />}
      label={label}
      labelPlacement='top'
    />
  );
}

import React, { useEffect } from 'react';
import UniversitiesTable from '../../style-components/Tables/UniversitiesTable.jsx';
import BoxContainer from '../../style-components/BoxContainer.jsx';
import { useGetUniversities } from '../../models/universities.js';
import Loading from '../../style-components/Loading.jsx';
import { getCurrentUser } from '../../models/user.js';
import { useNavigate } from 'react-router-dom';
const UniversitiesList = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser()
      .then(user => {
        if (user.role !== 'ADMIN') {
          navigate('/unauthorized');
        }
      })
      .catch();
  }, []);

  const [universities, loadingUniversities] = useGetUniversities();

  return (
    <BoxContainer>
      {loadingUniversities ? <Loading /> : <UniversitiesTable universities={universities} />}
    </BoxContainer>
  );
};

export default UniversitiesList;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import EvaluationRankingTable from '../../style-components/Tables/EvaluationRankingTable.jsx';
import { useGetEvaluation, getRanking } from '../../models/evaluations.js';
import { useGetCourse } from '../../models/course.js';
import { useGetLearnunits } from '../../models/learnunits.js';

const EvaluationQuestions = () => {
  const { id, unit_id, eval_id } = useParams();
  const [course] = useGetCourse(id);
  const [learnunits] = useGetLearnunits();
  const learnunit = learnunits?.data?.find(unit => unit.id === parseInt(unit_id));
  const [evaluation] = useGetEvaluation(eval_id);
  const [rankingData, setRankingData] = useState([]);

  const ranking = async () => {
    const rankingFetch = await getRanking(eval_id);
    setRankingData(rankingFetch);
  };

  useEffect(() => {
    ranking();
  }, [eval_id]);

  return (
    <BoxContainer>
      <EvaluationRankingTable
        evaluationId={eval_id}
        course={course}
        learningUnit={learnunit}
        evaluation={evaluation}
        ranking={rankingData}
      />
    </BoxContainer>
  );
};

export default EvaluationQuestions;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import Header from './Header.jsx';
import BoxContainer from '../../style-components/BoxContainer.jsx';

const PNFContainer = () => {
  const navigate = useNavigate();

  const styles = {
    title: {
      fontWeight: 'bold',
      margin: '10px',
    },
    margin: {
      margin: '10px',
    },
  };

  return (
    <Header>
      <BoxContainer>
        <Typography variant='h5' color='primary' sx={styles.title}>
          404 Not Found
        </Typography>
        <ReportProblemOutlinedIcon sx={styles.margin} color='primary' fontSize='large' />
        <div style={styles.margin}>
          <Typography>¡Oh no!</Typography>
          <Typography>Página no encontrada.</Typography>
        </div>
        <Button sx={styles.margin} variant='contained' onClick={() => navigate(-1)}>
          Volver
        </Button>
      </BoxContainer>
    </Header>
  );
};

export default PNFContainer;

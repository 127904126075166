import React, { useState } from 'react';
import { getDateFromISODate, getTimeFromISODate } from '../../../utils/date';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ChatTopbar({ nameChat, selectedUser, stats, lastUpdated }) {
  const [open, setOpen] = useState(false);
  const date = getDateFromISODate(lastUpdated);
  const time = getTimeFromISODate(lastUpdated);
  const models = ['GPT-3.5', 'GPT-4', 'PINECONE', 'LLAMA3'];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className='w-full h-20 flex p-4 justify-between items-center border-b'>
      <div className='flex items-center gap-2'>
        <div className='flex flex-col text-left'>
          <span className='font-medium'>
            {selectedUser.firstnames} {selectedUser.lastnames}
          </span>
          <span className='text-xs'>
            Última actualización: {date} a las {time}
          </span>
        </div>
      </div>

      <div>
        <IconButton aria-label='info' onClick={handleOpen}>
          <InfoOutlinedIcon />
        </IconButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Nombre del chat: {nameChat}
            </Typography>
            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
              Este chat pertenece a{' '}
              <code>
                {selectedUser.firstnames} {selectedUser.lastnames}
              </code>{' '}
              y fue actualizado por última vez el <code>{date}</code> a las <code>{time}</code>.
            </Typography>
            <Typography id='modal-modal-stats' sx={{ mt: 2 }} variant='h6'>
              Estadísticas del chat:
            </Typography>
            {models.map(model => (
              <Typography key={model} variant='body1' sx={{ mt: 2 }}>
                - {model}: {stats[model] ? stats[model] : 0} mensajes
              </Typography>
            ))}
          </Box>
        </Modal>
      </div>
    </div>
  );
}

import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

export const postPushNotification = async notification => {
  const token = await authorizationHeader();
  const response = await api
    .post('/notifications/send', notification, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

export const postPushNotificationToCourse = async (course_id, notification) => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/notifications/send/${course_id}`, notification, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

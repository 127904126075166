import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
  },
};

const Loading = () => {
  return (
    <Box sx={styles.box}>
      <CircularProgress size={30} />
    </Box>
  );
};

export default Loading;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Modal from '../Modal.jsx';
import QuestionForm from '../../components/Questions/QuestionForm.jsx';
import Loading from '../Loading.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Checkbox from '../Form/Checkbox.jsx';
import ImageComponent from '../ImageComponent.jsx';
import { deleteQuestionEvaluation } from '../../models/evaluations.js';
import { useGetQuestionByEvaluation } from '../../models/questions';

const correctAnswerVF = { 0: 'F', 1: 'V' };

const EvaluationQuestionsTable = ({ evaluationId, course, learningUnit, evaluation }) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [questions, setQuestions] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: questions.length,
  });
  const [loading, setLoading] = useState(false);
  const [questionsData, questionsDataLoading] = useGetQuestionByEvaluation(evaluationId);
  const [showImages, setShowImages] = useState(true);
  const [open, setOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [method, setMethod] = useState('POST');

  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      { name: 'Unidades de aprendizaje', href: `/courses/${course?.id}/learning_units` },
      {
        name: 'Evaluaciones',
        href: `/courses/${course?.id}/${learningUnit?.id}/evaluations`,
      },
      {
        name: `Evaluación ${evaluation?.name} del curso ${course?.name} de la unidad ${learningUnit?.name}`,
        href: '',
      },
    ],
    maxItems: 4,
  };

  const actions = [
    {
      title: 'Importar preguntas',
      icon: <AddIcon />,
      handleClick: () =>
        navigate(`/courses/${course?.id}/${learningUnit?.id}/${evaluationId}/import_questions`),
    },
    {
      title: 'Añadir del banco de preguntas',
      icon: <AddIcon />,
      handleClick: () =>
        navigate(`/courses/${course?.id}/${learningUnit?.id}/${evaluationId}/select_questions`),
    },
    {
      title: 'Crear pregunta',
      icon: <AddIcon />,
      handleClick: () => {
        setQuestionToEdit(null);
        setMethod('POST');
        setOpen(true);
      },
    },
    {},
  ];

  const handleDelete = async questionId => {
    const confirmation = window.confirm(
      '¿Estás seguro de eliminar esta pregunta de la evaluación?'
    );
    if (confirmation) {
      const response = await deleteQuestionEvaluation(questionId, evaluationId);
      if (response.status === 200 || response.status === 201) {
        alert('Pregunta eliminada.');
        window.location.reload();
      } else {
        alert('Error al eliminar la pregunta.');
      }
    }
  };

  useEffect(() => {
    if (questionsData && !questionsDataLoading) {
      setQuestions(questionsData);
      setPagination({
        currentPage: page,
        itemsPerPage: itemsPerPage,
        totalPages: Math.ceil(questionsData.length / itemsPerPage),
      });
      setLoading(false);
    }
  }, [questionsData, questionsDataLoading, page, itemsPerPage]);

  return (
    <div className='antialiased font-sans'>
      <Modal open={open} setOpen={setOpen}>
        <QuestionForm
          setOpen={setOpen}
          method={method}
          question={questionToEdit}
          unit_id={learningUnit?.id}
          eval_id={evaluationId}
        />
      </Modal>
      <div className='w-full divide-y divide-primary'>
        <HeaderContainer breadcrumb={itemsBreadcrumb} actions={actions} />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <div className='flex justify-between items-center mb-4'>
            <Checkbox
              label='Ver imágenes'
              defaultChecked
              onChange={() => setShowImages(!showImages)}
            />
          </div>
          <Table>
            <TableHead>
              <TableRow>
                {showImages ? <TableHeader>Imagen</TableHeader> : null}
                <TableHeader>Pregunta</TableHeader>
                <TableHeader>Respuesta correcta</TableHeader>
                <TableHeader>Tipo</TableHeader>
                <TableHeader>Tiempo (seg.)</TableHeader>
                <TableHeader>Mesa</TableHeader>
                <TableHeader>Orden</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {questionsDataLoading || loading ? (
                <TableRow>
                  <TableCell colSpan='9'>
                    <div className='flex justify-center items-center h-full'>
                      <Loading />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                questions.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(question => (
                  <TableRow key={question.id}>
                    {showImages ? (
                      <TableCell>
                        {question.image_id ? (
                          <ImageComponent image_id={question.image_id} />
                        ) : (
                          'Sin imagen asociada'
                        )}
                      </TableCell>
                    ) : null}
                    <TableCell>{question.content}</TableCell>
                    <TableCell>
                      {question.question_type === 'ALTERNATIVAS'
                        ? question.possible_answers[question.correct_answer]
                        : question.question_type === 'V/F'
                        ? correctAnswerVF[question.correct_answer]
                        : question.possible_answers.join(' | ')}
                    </TableCell>
                    <TableCell>{question.question_type}</TableCell>
                    <TableCell>{question.time_to_answer}</TableCell>
                    <TableCell>{question.table}</TableCell>
                    <TableCell>{question.order}</TableCell>
                    <TableCell>
                      <div className='flex w-full justify-between gap-2 items-center'>
                        <TableButton
                          variant={'edit'}
                          onClick={() => {
                            setQuestionToEdit(question);
                            setMethod('PATCH');
                            setOpen(true);
                          }}>
                          <EditOutlinedIcon />
                        </TableButton>
                        <TableButton variant={'delete'} onClick={() => handleDelete(question.id)}>
                          <DeleteOutlinedIcon color='red' />
                        </TableButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Pagination
            pagination={pagination}
            setPage={setPage}
            setLoading={setLoading}
            setItemPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default EvaluationQuestionsTable;

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';

const PrimaryButton = styled(Button)(({ theme }) => ({
  padding: '7px 20px',
  borderRadius: '50px',
  textTransform: 'none',
  flexWrap: 'nowrap',
  transitionDuration: '0.2s',
  color: theme.palette.getContrastText(blue[500]),
  boxShadow: 'none',
  backgroundColor: '#004591',
  '&:hover': {
    backgroundColor: '#4a90e2',
    color: theme.palette.getContrastText(blue[700]),
    boxShadow: 'none',
  },
}));

export default PrimaryButton;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

import Modal from '../Modal.jsx';
import NewLearnunit from '../../components/Courses/NewLearnunit.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import { SortData } from '../../components/Custom-UI/Tables/Filters.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import { deleteCourseLearunit } from '../../models/learnunits.js';

function CourseLearnunitTable({ courseLearnunits, course }) {
  const navigate = useNavigate();

  const [courseLearnunitsData, setCourseLearnunitsData] = useState(courseLearnunits || []);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);

  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      { name: `Unidades de aprendizaje de ${course.name}`, href: '' },
    ],
    maxItems: 2,
  };

  function sortDataByPath(path, direction) {
    const sortedData = SortData(courseLearnunitsData, path, direction);
    setCourseLearnunitsData(sortedData);
  }

  function handleSearch(option) {
    setSearchValue(option.target.value?.toLowerCase());
  }

  const handleDelete = async courseLearnunitId => {
    const confirmation = window.confirm(
      '¿Estás seguro que quieres eliminar esta unidad de aprendizaje?'
    );
    if (confirmation) {
      const response = await deleteCourseLearunit(courseLearnunitId);
      if (response?.status === 200) {
        alert('Unidad de aprendizaje eliminada.');
        window.location.reload();
      } else if (response?.response?.status === 400) {
        alert('Primero debes eliminar todas las evaluaciones de la unidad de aprendizaje.');
      } else {
        alert('Error al eliminar la unidad de aprendizaje.');
      }
    }
  };

  return (
    <div className='antialiased font-sans w-full'>
      <Modal open={open} setOpen={setOpen}>
        <NewLearnunit setOpen={setOpen} course_id={course.id} />
      </Modal>
      <div className='divide-y divide-primary'>
        <HeaderContainer
          breadcrumb={itemsBreadcrumb}
          search={{ handleSearch: handleSearch }}
          actions={[
            {
              title: 'Añadir Unidad de Aprendizaje',
              icon: <AddIcon />,
              handleClick: () => setOpen(true),
            },
            {},
          ]}
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader sortable onSort={direction => sortDataByPath('name', direction)}>
                  Nombre
                </TableHeader>
                <TableHeader>Fecha de Inicio</TableHeader>
                <TableHeader>Fecha de Término</TableHeader>
                <TableHeader>Evaluaciones</TableHeader>
                <TableHeader>Bibliografía</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {courseLearnunitsData.map(courseLearnunit =>
                courseLearnunit.name.toLowerCase().includes(searchValue) ? (
                  <TableRow key={courseLearnunit.id}>
                    <TableCell>{courseLearnunit.name}</TableCell>
                    <TableCell>{courseLearnunit.starting_date.split('T')[0]}</TableCell>
                    <TableCell>{courseLearnunit.ending_date.split('T')[0]}</TableCell>
                    <TableCell>
                      <TableButton
                        variant={'text'}
                        onClick={() =>
                          navigate(
                            `/courses/${course.id}/${courseLearnunit.learnunit_id}/evaluations`
                          )
                        }>
                        <div className='inline-flex items-center my-2'>
                          <div className='mr-1'>
                            <QuizOutlinedIcon />
                          </div>
                          Evaluaciones
                        </div>
                      </TableButton>
                    </TableCell>
                    <TableCell>
                      <TableButton
                        variant={'text'}
                        onClick={() =>
                          navigate(
                            `/courses/${course.id}/${courseLearnunit.learnunit_id}/bibliography/course_learnunit/${courseLearnunit.id}`
                          )
                        }>
                        <div className='inline-flex items-center my-2'>
                          <div className='mr-1'>
                            <BookOutlinedIcon />
                          </div>
                          Bibliografía
                        </div>
                      </TableButton>
                    </TableCell>
                    <TableCell>
                      <TableButton
                        variant={'delete'}
                        onClick={() => handleDelete(courseLearnunit.id)}>
                        <DeleteOutlinedIcon />
                      </TableButton>
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default CourseLearnunitTable;

import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectAndSearch({
  label = '',
  name,
  value,
  onChange,
  required = false,
  options = [],
  sx = {},
  disabled = false,
}) {
  return (
    <Autocomplete
      id='selectAndSearch'
      name={name}
      disablePortal
      options={options}
      getOptionLabel={option => (option.label ? option.label : '')}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      value={value}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          required={required}
          disabled={disabled}
          sx={{ textAlign: 'start', ...sx }}
        />
      )}
    />
  );
}

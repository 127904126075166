import React, { useState, useEffect } from 'react';
import validator from 'validator';

import Form from '../../style-components/Form/Form.jsx';
import Select from '../../style-components/Form/Select.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import Password from '../../style-components/Form/Password.jsx';
import DatePicker from '../../style-components/Form/DatePicker.jsx';
import { postUser, patchUser } from '../../models/user';
import { useGetUniversities } from '../../models/universities';
import { normalize } from '../../utils/normalize.js';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
};

function UserForm({ user, method, setOpen }) {
  const [universities, setUniversities] = useState([]);
  const [universitiesData, universitiesDataLoading] = useGetUniversities();
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    email: user?.email ?? '',
    role: user?.role ?? 'ADMIN',
    student_id: user?.student_id ?? '',
    firstnames: user?.firstnames ?? '',
    lastnames: user?.lastnames ?? '',
    birthday: user?.birthday ?? '',
    gender: user?.gender ?? '',
    country: user?.country ?? '',
    province: user?.province ?? '',
    establishment: user?.establishment ?? '',
    entry_way: user?.entry_way ?? 'PSU, PTU O PAES',
    times_course_taken: user?.times_course_taken ?? '1',
    previous_career: user?.previous_career ?? 'NO TIENE',
    university_id: user?.university_id ?? '',
    password: '',
    meta_values: [
      0, // Utilizo estrategias de estudio
      0, // Reflexivo
      0, // Organizado mi estudio
      0, // Exigente conmigo mismo
      0, // Creativo
      0, // Estructurado
      0, // Esforzado
      0, // Flexible
      0, // Responsable
      0, // Gestiono mi tiempo
    ],
  });

  useEffect(() => {
    const setDataFunction = () => {
      if (universitiesData && !universitiesDataLoading) {
        setUniversities(
          universitiesData.map(university => ({ value: university.id, label: university.name }))
        );
      }
    };
    setDataFunction();
  }, [universitiesData, universitiesDataLoading]);

  const handleSubmit = async () => {
    const toSend = { ...formState };
    if (
      method === 'POST' &&
      !validator.isStrongPassword(toSend.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      alert(
        'La contraseña debe tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 número y 1 símbolo.'
      );
      return;
    }
    if (!validator.isEmail(toSend.email)) {
      alert('El email no es válido.');
      return;
    }

    if (toSend.role === 'ADMIN' || toSend.role === 'TEACHER') {
      toSend.student_id = null;
      toSend.birthday = null;
      toSend.country = null;
      toSend.province = null;
      toSend.establishment = null;
      toSend.entry_way = null;
      toSend.times_course_taken = null;
      toSend.previous_career = null;
      toSend.meta_values = null;
    } else {
      toSend.country = normalize(toSend.country);
      toSend.province = normalize(toSend.province);
    }

    setLoading(true);
    let response;
    if (method === 'POST') {
      response = await postUser(toSend);
    } else if (method === 'PATCH') {
      response = await patchUser(toSend, user.id);
    }
    setLoading(false);

    if (response.status === 200 || response.status === 201) {
      alert(`Usuario ${method === 'POST' ? 'creado' : 'editado'} con éxito.`);
      setOpen(false);
      window.location.reload();
    } else {
      alert(`Ocurrió un error, intente de nuevo.`);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <Form
      title={`${method === 'POST' ? 'Añadir' : 'Editar'} usuario`}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      <div style={styles.container}>
        <div>
          <Select
            name='role'
            label='Rol'
            value={formState.role}
            onChange={handleChange}
            required
            options={[
              { value: 'ADMIN', label: 'Administrador' },
              { value: 'TEACHER', label: 'Profesor' },
              { value: 'STUDENT', label: 'Estudiante' },
            ]}
          />
          <Select
            name='university_id'
            label='Universidad'
            value={formState.university_id}
            onChange={handleChange}
            required
            options={universities}
          />
          <TextField
            label='Nombre/s'
            name='firstnames'
            value={formState.firstnames}
            onChange={handleChange}
            required
          />
          <TextField
            label='Apellido/s'
            name='lastnames'
            value={formState.lastnames}
            onChange={handleChange}
            required
          />
          <Select
            name='gender'
            label='Género'
            value={formState.gender}
            onChange={handleChange}
            required
            options={[
              { value: 'FEMENINO', label: 'Femenino' },
              { value: 'MASCULINO', label: 'Masculino' },
              { value: 'OTRO', label: 'Otro' },
              { value: 'PREFIERO NO INFORMARLO', label: 'Prefiero no informarlo' },
            ]}
          />
          <TextField
            label='Email'
            name='email'
            value={formState.email}
            onChange={handleChange}
            required
          />

          {method === 'POST' && (
            <Password
              label='Contraseña'
              name='password'
              type='password'
              value={formState.password}
              onChange={handleChange}
              required
              helperText='La contraseña debe tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 número y 1 símbolo.'
            />
          )}
        </div>
        {formState.role === 'STUDENT' ? (
          <div>
            <TextField
              label='ID estudiante'
              name='student_id'
              value={formState.student_id}
              onChange={handleChange}
              required
            />
            <DatePicker
              label='Fecha de nacimiento'
              name='birthday'
              value={formState.birthday}
              setFormState={setFormState}
              required
              maxDate={new Date()}
            />
            <TextField
              label='País'
              name='country'
              value={formState.country}
              onChange={handleChange}
              required
            />
            <TextField
              label='Comuna/Provincia'
              name='province'
              value={formState.province}
              onChange={handleChange}
              required
            />
            <Select
              name='establishment'
              label='Tipo de establecimiento de estudios secundarios'
              value={formState.establishment}
              onChange={handleChange}
              required
              options={[
                { value: 'PUBLICO', label: 'Público' },
                { value: 'PRIVADO SUBVENCIONADO', label: 'Privado subvencionado' },
                { value: 'PRIVADO', label: 'Privado' },
                { value: 'OTRO', label: 'Otro' },
              ]}
            />
            <Select
              name='entry_way'
              label='Vía de entrada a la carrera'
              value={formState.entry_way}
              onChange={handleChange}
              required
              options={[
                { value: 'PSU, PTU O PAES', label: 'PSU, PTU o PAES' },
                { value: 'COLLEGE/BACHILLERATO', label: 'College/Bachillerato' },
                { value: 'CUPO ESPECIAL', label: 'Cupo especial' },
                { value: 'ACCESO DIRECTO EQUIDAD', label: 'Acceso directo equidad' },
                { value: 'SEGUNDO GRADO ACADEMICO', label: 'Segundo grado académico' },
                {
                  value: 'CICLOS FORMATIVOS DE SEGUNDO GRADO',
                  label: 'Ciclos formativos de segundo grado',
                },
                { value: 'OTRO', label: 'Otro' },
              ]}
            />
            <Select
              name='times_course_taken'
              label='Veces que ha cursado el curso actual'
              value={formState.times_course_taken}
              onChange={handleChange}
              required
              options={[
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
              ]}
            />
            <Select
              name='previous_career'
              label='Carrera previa'
              value={formState.previous_career}
              onChange={handleChange}
              required
              options={[
                { value: 'NO TIENE', label: 'No tiene' },
                { value: 'FISIOTERAPIA', label: 'Fisioterapia' },
                { value: 'ENFERMERIA', label: 'Enfermería' },
                { value: 'CIENCIAS BIOMEDICAS', label: 'Ciencias Biomédicas' },
                { value: 'INGENIERIA BIOMEDICA', label: 'Ingeniería Biomédica' },
                { value: 'BIOLOGIA', label: 'Biología' },
                { value: 'BIOQUIMICA', label: 'Bioquímica' },
                { value: 'OTRA', label: 'Otra' },
              ]}
            />
          </div>
        ) : null}
      </div>
    </Form>
  );
}

export default UserForm;

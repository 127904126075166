import React from 'react';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import ImagesTable from '../../style-components/Tables/ImagesTable.jsx';

function ImagesList() {
  return (
    <BoxContainer>
      <ImagesTable />
    </BoxContainer>
  );
}

export default ImagesList;

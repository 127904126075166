import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// POST evaluation (Comunidades, torneos, pretest y posttest)
export const postEvaluation = async (course_id, ua_id, evaluation) => {
  const token = await authorizationHeader();
  const response = await api
    .post(`/evaluations/course/${course_id}/learnunits/${ua_id}/new`, evaluation, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// GET all evaluations
export function useGetEvaluations(page, limit) {
  const { value, loading, error } = useAsync(() => getEvaluations(page, limit), [page, limit]);
  return [value, loading, error];
}

export const getEvaluations = async (page, limit) => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations?page=${page}&limit=${limit}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response.data;
};

export const getAllEvaluations = async () => {
  const data = await getEvaluations(1, 99999);
  return data?.evaluations;
};

export function useGetAllEvaluations() {
  const { value, loading, error } = useAsync(() => getAllEvaluations());
  return [value, loading, error];
}

// GET Tournaments from course and unit
export function useGetCourseTournaments(course_id, unit_id) {
  const { value, loading, error } = useAsync(
    () => getCourseTournaments(course_id, unit_id),
    [course_id, unit_id]
  );
  return [value, loading, error];
}

export const getCourseTournaments = async (course_id, unit_id) => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/course/${course_id}/learnunits/${unit_id}/?eval_type=Tournament`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 404) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return response.data;
};

// GET tournament by id
export function useGetTournament(id) {
  const { value, loading, error } = useAsync(() => getTournament(id), [id]);
  return [value, loading, error];
}

export const getTournament = async id => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/tournaments/${id}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response.data;
};

// GET Communities from course and unit
export function useGetCourseCommunities(course_id, unit_id) {
  const { value, loading, error } = useAsync(
    () => getCourseCommunities(course_id, unit_id),
    [course_id, unit_id]
  );
  return [value, loading, error];
}

export const getCourseCommunities = async (course_id, unit_id) => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/course/${course_id}/learnunits/${unit_id}/?eval_type=Community`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 404) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return response.data;
};

// GET pretests from course and unit
export function useGetCoursePretests(course_id, unit_id) {
  const { value, loading, error } = useAsync(
    () => getCoursePretests(course_id, unit_id),
    [course_id, unit_id]
  );
  return [value, loading, error];
}

export const getCoursePretests = async (course_id, unit_id) => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/course/${course_id}/learnunits/${unit_id}/?eval_type=Pre-Test`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 404) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return response.data;
};

// GET posttests from course and unit
export function useGetCoursePosttests(course_id, unit_id) {
  const { value, loading, error } = useAsync(
    () => getCoursePosttests(course_id, unit_id),
    [course_id, unit_id]
  );
  return [value, loading, error];
}

export const getCoursePosttests = async (course_id, unit_id) => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/course/${course_id}/learnunits/${unit_id}/?eval_type=Post-Test`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 404) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return response.data;
};

// GET Practices from course and unit
export function useGetCoursePractices(course_id, unit_id) {
  const { value, loading, error } = useAsync(
    () => getCoursePractices(course_id, unit_id),
    [course_id, unit_id]
  );
  return [value, loading, error];
}

export const getCoursePractices = async (course_id, unit_id) => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/course/${course_id}/learnunits/${unit_id}/?eval_type=Practice`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 404) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return response.data;
};

// GET evaluations (Comunidades, torneos, pretest y posttest) by id
export function useGetEvaluation(id) {
  const { value, loading, error } = useAsync(() => getEvaluation(id), [id]);
  return [value, loading, error];
}

export const getEvaluation = async id => {
  if (!id) return;
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/${id}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response.data;
};

// PATCH evaluation (Comunidades, torneos, pretest y posttest) by id
export const patchEvaluation = async (evalId, evaluation) => {
  const token = await authorizationHeader();
  const response = await api
    .patch(`/evaluations/update/${evalId}`, evaluation, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// DELETE evaluation (Comunidades, torneos, pretest y posttest) by id
export const deleteEvaluation = async evalId => {
  const token = await authorizationHeader();
  const response = await api
    .delete(`/evaluations/delete/${evalId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// DELETE question evaluation
export const deleteQuestionEvaluation = async (questionId, evaluationId) => {
  const token = await authorizationHeader();
  const response = await api
    .delete(`/evaluations/delete_question/${questionId}/${evaluationId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// GET daily quiz evaluation and questions
export const getDailyQuiz = async course_id => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/daily-quiz/course/${course_id}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response.data;
};

export const publishDailyQuizQuestion = async (questionId, evaluationId, courseId, published) => {
  const token = await authorizationHeader();
  const response = await api
    .patch(
      `/evaluations/daily-quiz/${questionId}/${evaluationId}/${courseId}`,
      { published },
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

// GET Evaluations from course and unit
export function useGetCourseEvaluations(course_id, unit_id) {
  const { value, loading, error } = useAsync(
    () => getCourseEvaluations(course_id, unit_id),
    [course_id, unit_id]
  );
  return [value, loading, error];
}

export const getCourseEvaluations = async (course_id, unit_id) => {
  const token = await authorizationHeader();
  const response = await api
    .get(`/evaluations/course/${course_id}/learnunits/${unit_id}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      if (err?.response?.status !== 404) {
        Sentry.captureException(err);
        if (err?.response?.data) {
          Sentry.captureMessage(err.response.data);
        }
      }
      return err;
    });
  return response.data;
};

export const getRanking = async evaluationId => {
  const token = await authorizationHeader();
  const response = await api
    .get(`evaluation-attempt/ranking/${evaluationId}`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      return err;
    });
  return response.data;
};

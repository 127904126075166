import React from "react";
import ChatTopbar from "./ChatTopbar.jsx";
import { MessagesList } from "./MessagesList.jsx";

export function Chat({ nameChat, details, stats, selectedUser }) {
  return (
    <div className="flex flex-col justify-between w-full h-full">
      <ChatTopbar selectedUser={selectedUser} nameChat={nameChat} stats={stats} lastUpdated={details.updatedAt}/>

      <MessagesList
        messages={details.messages}
      />
    </div>
  );
}
import React from 'react';
import Typography from '@mui/material/Typography';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import Button from '@mui/material/Button';

import BoxContainer from '../style-components/BoxContainer.jsx';

function Unauthorized() {
  const styles = {
    title: {
      fontWeight: 'bold',
      margin: '10px',
    },
    margin: {
      margin: '10px',
    },
  };

  const handleClick = () => {
    window.history.go(-2);
  };

  return (
    <BoxContainer>
      <Typography variant='h5' color='primary' sx={styles.title}>
        No Autorizado
      </Typography>
      <BlockOutlinedIcon sx={styles.margin} color='primary' fontSize='large' />
      <div style={styles.margin}>
        <Typography>¡Alto Ahí!</Typography>
        <Typography>Usuario no autorizado para esta vista.</Typography>
      </div>
      <Button sx={styles.margin} variant='contained' onClick={handleClick}>
        Volver
      </Button>
    </BoxContainer>
  );
}

export default Unauthorized;

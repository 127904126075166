import React from 'react';
import TextField from '@mui/material/TextField';

export default function TextFieldInput({
  label = '',
  name,
  value,
  onChange,
  required = false,
  type = 'text',
  placeholder = '',
  multiline = false,
  helperText = '',
  error = false,
  minRows = 1,
}) {
  return (
    <TextField
      id='text-field'
      type={type}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
      multiline={multiline}
      helperText={helperText}
      error={error}
      minRows={minRows}
    />
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const CollapsedBreadcrumb = ({ items, maxItems }) => {
  const navigate = useNavigate();

  return (
    <div role='presentation'>
      <Breadcrumbs maxItems={maxItems} aria-label='breadcrumb' sx={{ '& *': { padding: 0 } }}>
        {items.slice(0, -1).map((item, index) => (
          <Link
            key={index}
            sx={{ cursor: 'pointer' }}
            underline='hover'
            color='primary'
            onClick={() => navigate(item.href)}>
            {item.name}
          </Link>
        ))}
        {items.length > 0 && (
          <Typography color='secondary'>{items[items.length - 1].name}</Typography>
        )}
      </Breadcrumbs>
    </div>
  );
};

export default CollapsedBreadcrumb;

import React from 'react';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import QuestionsTable from '../../style-components/Tables/QuestionsTable.jsx';

function QuestionsList() {
  return (
    <BoxContainer>
      <QuestionsTable />
    </BoxContainer>
  );
}

export default QuestionsList;

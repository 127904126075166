import React, { useState } from 'react';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '../../Custom-UI/Resizable.jsx';
import { cn } from '../../../utils/cn';
import { ChatSidebar } from './ChatSidebar.jsx';
import { Chat } from './Chat.jsx';


export function ChatLayout({ navCollapsedSize, chat }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <ResizablePanelGroup
        direction='horizontal'
        onLayout={sizes => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`;
        }}
        className='h-full items-stretch'>
        <ResizablePanel
            defaultSize={28}
            collapsedSize={navCollapsedSize}
            collapsible={true}
            minSize={25}
            maxSize={30}
            onCollapse={() => {
              setIsCollapsed(true);
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(true)}`;
            }}
            onExpand={() => {
              setIsCollapsed(false);
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(false)}`;
            }}
            className={cn(
              isCollapsed && 'min-w-[80px] transition-all duration-300 ease-in-out'
            )}>
            <ChatSidebar
                userId={chat.user.id}
              isCollapsed={isCollapsed}
            />
          </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={48} minSize={30}>
          <Chat nameChat={chat.name} details={chat.details} stats={chat.messageModelStats} selectedUser={chat.user} />
        </ResizablePanel>
      </ResizablePanelGroup>
    </>
  );
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import UsersTable from '../../style-components/Tables/UsersTable.jsx';
import BoxContainer from '../../style-components/BoxContainer.jsx';
import { getCurrentUser } from '../../models/user.js';

const UsersList = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser()
      .then(user => {
        if (user.role !== 'ADMIN') {
          navigate('/unauthorized');
        }
      })
      .catch();
  }, []);

  return (
    <BoxContainer>
      <UsersTable />
    </BoxContainer>
  );
};

export default UsersList;

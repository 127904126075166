import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import NavBar from './NavBar.jsx';
import Loading from '../../style-components/Loading.jsx';
import { getCurrentUser } from '../../models/user';
import { sideMenuTeacher, sideMenuAdmin } from '../constants/constant';
import { signOut } from '../../utils/cognitoAuth';
import backgroundImage from '../../assets/images/IMG_BACKGROUND_LOGIN.png';
import logoHorizontal from '../../assets/images/LOGO_with_letters_horizontal.svg';
import arrow from '../../assets/images/ICON_back_arrow.svg';

function Header({ children }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const [showNavBar, setShowNavBar] = useState(true);

  const styles = {
    backgroundImage: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      paddingLeft: '30px',
    },
    navbarContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
    },
    name: {
      color: theme.palette.white.main,
    },
    arrow: {
      position: 'fixed',
      bottom: '20px',
      left: showNavBar ? '40px' : '10px',
      transform: showNavBar ? 'rotate(0deg)' : 'rotate(180deg)',
    },
  };

  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([]);
  const [userName, setUserName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setLoading(true);
    getCurrentUser()
      .then(user => {
        switch (user?.role) {
          case 'TEACHER':
            setUserName(`${user?.firstnames} ${user?.lastnames}`);
            setMenu(sideMenuTeacher);
            setLoading(false);
            break;
          case 'ADMIN':
            setUserName(`${user?.firstnames} ${user?.lastnames}`);
            setMenu(sideMenuAdmin);
            setLoading(false);
            break;
          default:
            setLoading(false);
            break;
        }
      })
      .catch(() => setLoading(false));
  }, []);

  const handleClick = () => {
    setShowNavBar(!showNavBar);
  };

  const handleLogo = () => {
    navigate('/index');
  };

  return (
    <div>
      <div style={styles.backgroundImage}>
        <div style={styles.header}>
          <img src={logoHorizontal} alt='Logo' style={styles.image} onClick={handleLogo} />
          <div>
            {userName ? (
              <Typography variant='h7' style={styles.name}>
                Bienvenido/a <b>{userName}</b>
              </Typography>
            ) : null}
            <IconButton
              id='basic-button'
              color='white'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={event => setAnchorEl(event.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(false)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  navigate('/profile');
                }}
                color={theme.palette.primary.main}>
                Mi perfil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  signOut();
                  navigate('/');
                }}
                color={theme.palette.primary.main}>
                Cerrar sesión
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      {loading && <Loading />}
      {showNavBar && !loading && (
        <div style={styles.navbarContainer}>
          <NavBar menu={menu} />
          <div className='relative w-full mx-auto items-center'>
            <Outlet />
            {children}
          </div>
        </div>
      )}
      {!showNavBar && !loading && (
        <>
          <Outlet />
          {children}
        </>
      )}
      {userName ? <img src={arrow} alt='Arrow' style={styles.arrow} onClick={handleClick} /> : null}
    </div>
  );
}

export default Header;

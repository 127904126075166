import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import PrivateRoute from '../utils/PrivateRoute.jsx';
import Evaluations from './Evaluations/Evaluations.jsx';
import LogIn from './PreLogin/LogIn.jsx';
import PrivacyPolicy from './PreLogin/PrivacyPolicy.jsx';
import LearningUnits from './Courses/LearningUnits.jsx';
import EvaluationQuestions from './Questions/EvaluationQuestions.jsx';
import LearnunitQuestions from './Questions/LearnunitQuestions.jsx';
import ImportStudents from './Courses/ImportStudents.jsx';
import UsersList from './Users/UserList.jsx';
// import EvaluationDashboard from './Dashboards/Evaluations/EvaluationDashboard';
// import EvaluationsDashboards from './Dashboards/Evaluations/DashboardsView';
// import TimeDashboard from './Dashboards/TimeDashboard';
// import TopicDashboard from './Dashboards/UADashboard';
import BibliographyList from './Bibliography/BibliographyList.jsx';
import ImagesList from './Images/ImageList.jsx';
import QuestionsList from './Questions/QuestionsList.jsx';
import UniversitiesList from './Universities/UniversitiesList.jsx';
// import StudentsDashboard from './Dashboards/Students/StudentsDashboard';
// import UACourseDashboard from './Dashboards/UACourseDashboard.jsx';
import CourseListAll from './Courses/CourseListAll.jsx';
import PageNotFound from './NavBar/PageNotFound.jsx';
import LandingPage from './LandingPage/LandingPage.jsx';
import Students from './Courses/Students.jsx';
import ProfileView from './Users/ProfileView.jsx';
import Unauthorized from './Unauthorized.jsx';
import ImportQuestions from './Questions/ImportQuestions.jsx';
// import DailyQuiz from './Questions/DailyQuiz';
import Laminaria from './Laminaria/LaminariaUpload.jsx';
import ChatList from './Chats/ChatList.jsx';
import ChatMessages from './Chats/ChatMessages.jsx';
import Ranking from './Evaluations/Ranking.jsx';
import Terms from './Terms/Terms.jsx';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004591',
    },
    secondary: {
      main: '#4a90e2',
    },
    success: {
      main: '#7ed321',
    },
    background: {
      main: '#f1f6ff',
    },
    white: {
      main: '#ffffff',
    },
    black: {
      main: '#000000',
    },
    red: {
      main: '#ff0000',
    },
    // surface: '#cadded',
    // surfaceVariant: '#f1f6ff',
    // secondaryContainer: '#cadded',
    // outline: '#004591',
    // black: '#000000',
    // lightGreen: '#e8ffcf',
    // green2: '#68b414',
    // darkGrey: '#4a4a4a',
    // grey: '#9b9b9b',
    // lightGrey: '#e9e9e9',
    // lightRed: '#fed1d1',
    // yellow: '#f5a623',
  },
  typography: {
    allVariants: {
      color: '#004591',
    },
  },
});

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <div className='App'>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<LogIn />} />
              <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='*' element={<PageNotFound />} />
              <Route exact path='' element={<PrivateRoute />}>
                <Route path='/unauthorized' element={<Unauthorized />} />
                <Route path='/index' element={<LandingPage />} />
                <Route path='/universities' element={<UniversitiesList />} />
                <Route path='/courses/:id/learning_units' element={<LearningUnits />} />
                {/* <Route path='/courses/:id/:unit_id/dashboard' element={<UACourseDashboard />} /> */}
                <Route
                  path='/courses/:id/:unit_id/evaluations/:eval_id/question'
                  element={<EvaluationQuestions />}
                />
                <Route path='/users' element={<UsersList />} />
                <Route path='/bibliographies' element={<BibliographyList />} />
                <Route
                  path='/courses/:course_id/:unit_id/bibliography/course_learnunit/:course_learnunit_id'
                  element={<BibliographyList />}
                />
                <Route path='/images' element={<ImagesList />} />
                {/* <Route path='/evaluations/dashboard/:id' element={<EvaluationDashboard />} />
                <Route path='/statistics/evaluations' element={<EvaluationsDashboards />} />
                <Route path='/statistics/students' element={<StudentsDashboard />} />
                <Route path='/statistics/UA' element={<TopicDashboard />} />
                <Route path='/statistics/time' element={<TimeDashboard />} /> */}
                <Route path='/questions' element={<QuestionsList />} />
                <Route path='/courses' element={<CourseListAll />} />
                <Route path='/laminaria' element={<Laminaria />} />
                <Route path='/chats' element={<ChatList />} />
                <Route path='/chats/:id' element={<ChatMessages />} />
                <Route path='/courses/:id/:unit_id/evaluations' element={<Evaluations />} />
                <Route
                  path='/courses/:id/:unit_id/evaluations/:eval_id/questions'
                  element={<EvaluationQuestions />}
                />
                <Route
                  path='/courses/:id/:unit_id/evaluations/:eval_id/ranking'
                  element={<Ranking />}
                />
                <Route
                  path='/courses/:id/:unit_id/:eval_id/import_questions'
                  element={<ImportQuestions />}
                />
                <Route path='/questions/import_questions' element={<ImportQuestions />} />
                <Route
                  path='/courses/:id/:unit_id/:eval_id/select_questions'
                  element={<LearnunitQuestions />}
                />
                {/* <Route path='/courses/:id/daily_quiz' element={<DailyQuiz />} /> */}
                <Route path='/courses/:id/my_students' element={<Students />} />
                <Route path='/courses/:id/import_students' element={<ImportStudents />} />
                <Route path='/students' element={<Students />} />
                <Route path='/profile' element={<ProfileView />} />
                <Route path='/terms' element={<Terms />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;

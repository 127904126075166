import React, { useEffect, useState } from 'react';

import Form from '../../style-components/Form/Form.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import DatePicker from '../../style-components/Form/DatePicker.jsx';
import Switch from '../../style-components/Form/Switch.jsx';
import { postEvaluation, patchEvaluation } from '../../models/evaluations.js';

const evaluationsNames = {
  Practice: 'Práctica',
  Tournament: 'Torneo',
};

const EvaluationForm = ({
  eval_components,
  course_id,
  unit_id,
  method = 'POST',
  type = 'Practice',
  setOpen,
}) => {
  const [evaluation, setEvaluation] = useState({
    name: '',
    description: '',
    course_id: course_id,
    starting_date: type === 'Tournament' ? '' : null,
    ending_date: type === 'Tournament' ? '' : null,
    tables_per_evaluation: '',
    questions_per_table: '',
    eval_type: type,
    attempts: type === 'Tournament' ? 1 : null,
    show_tips: type === 'Tournament' ? false : true,
    learnunits_id: unit_id,
    API_calls_won: 0,
    API_calls_expire: 0,
    publish: false,
    notify: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (eval_components) {
      setEvaluation({
        ...evaluation,
        ...eval_components,
      });
    }
  }, [eval_components]);

  const handleChange = e => {
    const { name, type, checked, value } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setEvaluation({
      ...evaluation,
      [name]: newValue,
    });
  };

  const handleSubmit = async () => {
    if (evaluation.tables_per_evaluation < 1) {
      alert('El número de mesas por evaluación debe ser un número entero mayor que 0.');
      return;
    }
    if (!evaluation.questions_per_table) {
      evaluation.questions_per_table = null;
    } else if (
      isNaN(evaluation.questions_per_table) ||
      !Number.isInteger(Number(evaluation.questions_per_table)) ||
      Number(evaluation.questions_per_table) < 1
    ) {
      alert('El número de preguntas por mesa debe ser un número entero mayor que 0.');
      return;
    }
    if (evaluation.API_calls_won < 0) {
      alert(
        'El número de consultas a MediBot ganadas debe ser un número entero mayor o igual a 0.'
      );
      return;
    }
    if (evaluation.API_calls_expire < 0) {
      alert(
        'El número de días en que expiran las consultas a MediBot ganadas debe ser un número entero mayor o igual a 0.'
      );
      return;
    }

    setLoading(true);
    let res;
    if (method === 'POST') {
      res = await postEvaluation(course_id, unit_id, evaluation);
    } else if (method === 'PATCH') {
      if (evaluation.publish) {
        const notifyStudents = window.confirm(
          '¿Desea notificar a los estudiantes sobre la publicación de la evaluación?'
        );
        evaluation.notify = notifyStudents ? true : false;
      }
      res = await patchEvaluation(evaluation.id, evaluation);
    }
    setLoading(false);
    if (res.status === 200 || res.status === 201) {
      if (method === 'POST') {
        alert(`${evaluationsNames[type]} creado con éxito.`);
      } else if (method === 'PATCH') {
        alert(`${evaluationsNames[type]} editado con éxito.`);
      }
      setOpen(false);
      window.location.reload();
    } else {
      alert(`Error al crear o editar el ${evaluationsNames[type]}. Intente de nuevo.`);
    }
  };

  return (
    <Form
      title={`${method === 'POST' ? 'Añadir' : 'Editar'} ${evaluationsNames[type]}`}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      <TextField
        label='Nombre'
        name='name'
        value={evaluation.name}
        onChange={handleChange}
        required
      />
      <TextField
        label='Descripción'
        name='description'
        value={evaluation.description}
        onChange={handleChange}
        multiline
      />
      {type === 'Tournament' ? (
        <>
          <DatePicker
            name='starting_date'
            value={evaluation.starting_date}
            setFormState={setEvaluation}
            label='Fecha de inicio'
            required
            maxDate={evaluation.ending_date}
            type='datetime'
          />
          <DatePicker
            name='ending_date'
            value={evaluation.ending_date}
            setFormState={setEvaluation}
            label='Fecha de término'
            required
            minDate={evaluation.starting_date}
            type='datetime'
          />
        </>
      ) : null}
      <TextField
        label='Mesas por evaluación'
        type='number'
        name='tables_per_evaluation'
        value={evaluation.tables_per_evaluation}
        onChange={handleChange}
        required
      />
      <TextField
        label='Preguntas por mesa'
        type='number'
        name='questions_per_table'
        value={evaluation.questions_per_table}
        onChange={handleChange}
      />
      <TextField
        label='Consultas a MediBot ganadas'
        type='number'
        name='API_calls_won'
        value={evaluation.API_calls_won}
        onChange={handleChange}
        required
      />
      <TextField
        label='Días en que expiran las consultas a MediBot ganadas (0 es que no expiran)'
        type='number'
        name='API_calls_expire'
        value={evaluation.API_calls_expire}
        onChange={handleChange}
        required
      />
      <Switch
        name='publish'
        label='Publicar evaluación'
        value={evaluation.publish}
        onChange={handleChange}
      />
    </Form>
  );
};

export default EvaluationForm;

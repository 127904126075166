import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Modal, Box, Fade, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function VideoThumbnail({ video_id, video_name, timestamp }) {
  const [loadingImage, setLoadingImage] = useState(false);
  const [name, setName] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styles = {
    textContainer: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      fontSize: '16px',
      padding: '10px',
      textAlign: 'center',
      borderBottomLeftRadius: '20px',
      borderBottomRightRadius: '20px',
    },
    modalContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      position: 'relative',
      backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo oscuro al abrir el modal
    },
    modalContent: {
      outline: 'none',
      width: '80%',
      maxWidth: '800px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      padding: '0px',
      borderRadius: '8px',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '0px',
      right: '10px',
      zIndex: 1000,
      transitionDuration: '0.1s',
      '&:hover': {
        backgroundColor: 'gray',
        color: 'black',
      },
    },
    headerBar: {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      height: '40px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  };

  const convertTime = rawTime => {
    try {
      const parts = rawTime.toString().split(':');
      const minutes = parts[0];
      const seconds = parts[1].split('.')[0];
      return `${minutes}m${seconds}s`;
    } catch {
      return '0m0s';
    }
  };

  useEffect(() => {
    const obtainThumbnail = async () => {
      try {
        const response = await fetch(`https://api.vimeo.com/videos/${video_id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_VIMEO_TOKEN}`,
          },
        });

        const json = await response.json();
        const { link_with_play_button } = json.pictures.sizes[2];
        setThumbnail(link_with_play_button);
        setName(json.name);
      } catch (err) {
        // console.log(err);
      }
    };
    setLoadingImage(true);
    obtainThumbnail();
    setLoadingImage(false);
  }, [video_id]);

  return (
    <div className='p-2'>
      {loadingImage ? (
        <Skeleton sx={{ bgcolor: 'grey.600' }} variant='rounded' width={280} height={118} />
      ) : (
        <div className='relative'>
          <div onClick={handleOpen} className='cursor-pointer'>
            <img
              className='w-full rounded-3xl h-36 object-cover relative'
              src={thumbnail}
              alt={video_name || name}
            />
            <div style={styles.textContainer}>
              <p>{video_name || name}</p>
              {timestamp ? <p>{timestamp.split('.')[0]}</p> : null}
            </div>
          </div>
          <Modal open={open} onClose={handleClose} closeAfterTransition>
            <Fade in={open}>
              <Box sx={styles.modalContainer} onClick={handleClose}>
                <Box sx={styles.modalContent} onClick={e => e.stopPropagation()}>
                  <div style={styles.headerBar}>
                    <IconButton sx={styles.closeButton} onClick={handleClose} color={'white'}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <iframe
                    className='aspect-video w-full'
                    src={`https://player.vimeo.com/video/${video_id}?autoplay=1#t=${convertTime(
                      timestamp
                    )}&texttrack=es`}
                    allow='autoplay; fullscreen'
                    title={video_name || name}
                    allowFullScreen
                    style={{ border: 'none' }}
                  />
                </Box>
              </Box>
            </Fade>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default VideoThumbnail;

import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Modal from '../Modal.jsx';
import Loading from '../Loading.jsx';
import BibliographyForm from '../../components/Bibliography/BibliographyForm.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import { SortData } from '../../components/Custom-UI/Tables/Filters.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import { useGetBibliographies, deleteBibliography } from '../../models/bibliography.js';

function BibliographyTable({ courseLearnunitId, courseId, courseName, courseLearnunitName }) {
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [bibliographies, setBibliographies] = useState([]);
  const [data, dataLoading] = useGetBibliographies(page, itemsPerPage);
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: bibliographies.length,
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [method, setMethod] = useState('POST');
  const [bibliographyToEdit, setBiliographyToEdit] = useState({});
  const [open, setOpen] = useState(false);

  let actions = [];
  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      { name: 'Unidades de aprendizaje', href: `/courses/${courseId}/learning_units` },
      {
        name: `Bibliografía del curso ${courseName} de la unidad ${courseLearnunitName}`,
        href: '',
      },
    ],
    maxItems: 3,
  };
  if (courseLearnunitId) {
    actions = [
      {
        title: 'Añadir Recurso',
        icon: <AddIcon />,
        handleClick: () => {
          setBiliographyToEdit({});
          setMethod('POST');
          setOpen(true);
        },
      },
      {},
    ];
  }

  const handleDelete = async id => {
    const confirmation = window.confirm('¿Estás seguro que quieres eliminar este recurso?');
    if (confirmation) {
      const response = await deleteBibliography(id);
      if (response.status === 200 || response.status === 201) {
        alert('Recurso eliminada.');
        window.location.reload();
      } else {
        alert('Error al eliminar el recurso.');
      }
    }
  };

  function sortDataByPath(path, direction) {
    const sortedData = SortData(bibliographies, path, direction);
    setBibliographies(sortedData);
  }

  function handleSearch(option) {
    setSearchValue(option.target.value?.toLowerCase());
  }

  useEffect(() => {
    if (data && !dataLoading) {
      setBibliographies(data.bibliographies);
      setPagination({
        currentPage: data.pagination.currentPage,
        itemsPerPage: itemsPerPage,
        totalPages: data.pagination.pages,
      });
      setLoading(false);
    }
  }, [data, dataLoading]);

  return (
    <div className='antialiased font-sans w-full'>
      <Modal open={open} setOpen={setOpen}>
        <BibliographyForm
          setOpen={setOpen}
          method={method}
          bibliography={bibliographyToEdit}
          course_learnunit_id={courseLearnunitId}
        />
      </Modal>
      <div className='divide-y divide-primary'>
        <HeaderContainer
          breadcrumb={courseLearnunitId ? itemsBreadcrumb : null}
          title={'Bibliografías'}
          search={{ handleSearch: handleSearch }}
          actions={actions}
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader sortable onSort={direction => sortDataByPath('name', direction)}>
                  Nombre
                </TableHeader>
                <TableHeader>URL</TableHeader>
                <TableHeader>Descripción</TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLoading || loading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                bibliographies.map(bibliography =>
                  bibliography.name.toLowerCase().includes(searchValue) &&
                  (!courseLearnunitId ||
                    courseLearnunitId === bibliography.course_learnunit_id.toString()) ? (
                    <TableRow key={bibliography.id}>
                      <TableCell>{bibliography.name}</TableCell>
                      <TableCell>
                        <a href={bibliography.url} target='_blank' rel='noreferrer'>
                          <u>{bibliography.url}</u>
                        </a>
                      </TableCell>
                      <TableCell>{bibliography.details}</TableCell>
                      <TableCell>
                        <div className='flex w-full justify-between gap-2 items-center'>
                          <TableButton
                            variant={'edit'}
                            onClick={() => {
                              setBiliographyToEdit(bibliography);
                              setMethod('PATCH');
                              setOpen(true);
                            }}>
                            <EditOutlinedIcon />
                          </TableButton>
                          <TableButton
                            variant={'delete'}
                            onClick={() => handleDelete(bibliography.id)}>
                            <DeleteOutlinedIcon color='red' />
                          </TableButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : null
                )
              )}
            </TableBody>
          </Table>
          <Pagination
            pagination={pagination}
            setPage={setPage}
            setLoading={setLoading}
            setItemPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default BibliographyTable;

function FilterData(data, path, filterValue) {
  const getValueByPath = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  return data.filter(item => {
    const value = getValueByPath(item, path);
    return value.toString().toLowerCase().includes(filterValue.toLowerCase());
  });
}

function SortData(data, path, direction) {
  const getValueByPath = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  return [...data].sort((a, b) => {
    const aValue = getValueByPath(a, path);
    const bValue = getValueByPath(b, path);

    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    return 0;
  });
}

export { FilterData, SortData };

import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, ListItemIcon, TextField } from '@mui/material';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';

const SortMenu = ({ onSelect, onFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValue, setFilterValue] = useState('');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = direction => {
    onSelect(direction);
    handleClose();
  };

  const handleFilter = () => {
    onFilter(filterValue);
    handleClose();
  };

  return (
    <div>
      <IconButton sx={{ color: '#004591', '&:hover': { color: '#4a90e2' } }} onClick={handleClick}>
        {onSelect && <MoreVertIcon fontSize='small' />}
        {onFilter && <FilterListIcon fontSize='small' />}
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {onFilter && (
          <div style={{ padding: '8px' }}>
            <TextField
              label='Filter'
              variant='outlined'
              size='small'
              value={filterValue}
              onChange={e => setFilterValue(e.target.value)}
              fullWidth
            />
            <MenuItem onClick={handleFilter}>Apply</MenuItem>
          </div>
        )}
        {onSelect && (
          <div>
            <MenuItem onClick={() => handleSelect('asc')}>
              <ListItemIcon>
                <ArrowUpwardIcon fontSize='small' />
              </ListItemIcon>
              Asc
            </MenuItem>
            <MenuItem onClick={() => handleSelect('desc')}>
              <ListItemIcon>
                <ArrowDownwardIcon fontSize='small' />
              </ListItemIcon>
              Desc
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default SortMenu;

import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

export function useGetChats({
  page,
  limit,
  learnunitFilter,
  chatFilter,
  userFilter,
  searchFilter,
}) {
  const { value, loading, error } = useAsync(
    () => getChats(page, limit, learnunitFilter, chatFilter, userFilter, searchFilter),
    [page, limit, learnunitFilter, chatFilter, userFilter, searchFilter]
  );
  return [value, loading, error];
}

export const getChats = async (
  page = '',
  limit = '',
  learnunitFilter = '',
  chatFilter = '',
  userFilter = '',
  searchFilter = ''
) => {
  const token = await authorizationHeader();
  const baseUrl = '/MediBot/chats';
  const params = new URLSearchParams();

  if (page !== '') params.append('page', page);
  if (limit !== '') params.append('limit', limit);

  if (learnunitFilter !== '') params.append('learnunitFilter', learnunitFilter);
  if (chatFilter !== '') params.append('chatFilter', chatFilter);
  if (userFilter !== '') params.append('userFilter', userFilter);
  if (searchFilter !== '') params.append('searchFilter', searchFilter);

  const queryString = params.toString();
  const fullUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;

  const chats = await api
    .get(fullUrl, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });

  return chats.data;
};

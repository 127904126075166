import React from 'react';
import { useParams } from 'react-router-dom';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import EvaluationQuestionsTable from '../../style-components/Tables/EvaluationQuestionsTable.jsx';
import { useGetEvaluation } from '../../models/evaluations.js';
import { useGetCourse } from '../../models/course.js';
import { useGetLearnunits } from '../../models/learnunits.js';

const EvaluationQuestions = () => {
  const { id, unit_id, eval_id } = useParams();
  const [course] = useGetCourse(id);
  const [learnunits] = useGetLearnunits();
  const learnunit = learnunits?.data?.find(unit => unit.id === parseInt(unit_id));

  const [evaluation] = useGetEvaluation(eval_id);

  return (
    <BoxContainer>
      <EvaluationQuestionsTable
        evaluationId={eval_id}
        course={course}
        learningUnit={learnunit}
        evaluation={evaluation}
      />
    </BoxContainer>
  );
};

export default EvaluationQuestions;

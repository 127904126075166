import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import Loading from '../Loading.jsx';
import HeaderContainer from '../HeaderContainer.jsx';
import { SortData } from '../../components/Custom-UI/Tables/Filters.jsx';
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableButton,
} from '../../components/Custom-UI/Tables/Elements.jsx';
import Pagination from '../../components/Custom-UI/Tables/Pagination.jsx';
import Select from '../Form/Select.jsx';
import {
  patchUserMobileOrVR,
  swapUserMobileOrVR,
  useGetStudentsOfCourse,
  useGetUsersWithPagination,
  deleteUserFromCourse,
  getCurrentUser,
} from '../../models/user.js';

const StudentsTable = ({ courseId, courseName }) => {
  const navigate = useNavigate();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [students, setStudents] = useState([]);
  const [studentsCourseData, studentsCourseDataLoading] = useGetStudentsOfCourse(
    page,
    itemsPerPage,
    courseId
  );
  const [studentsData, studentsDataLoading] = useGetUsersWithPagination(
    page,
    itemsPerPage,
    'STUDENT'
  );
  const [pagination, setPagination] = useState({
    currentPage: page,
    itemsPerPage: itemsPerPage,
    totalPages: students.length,
  });
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [role, setRole] = useState('TEACHER');

  const swapStudentsMobileOrVR = async () => {
    try {
      const response = await swapUserMobileOrVR(courseId);
      if (response.status === 200 || response.status === 201) {
        alert(
          'Se han intercambiado la habilitación de aplicaciones de los estudiantes exitosamente.'
        );
        window.location.reload();
      } else {
        alert(
          'Hubo un error al intercambiar la habilitación de aplicaciones de los estudiante. Intente de nuevo.'
        );
      }
    } catch {
      alert(
        'Hubo un error al intercambiar la habilitación de aplicaciones de los estudiante. Intente de nuevo.'
      );
    }
  };

  let actions = [
    {
      title: 'Intercambiar habilitación aplicaciones',
      icon: <SwapHorizOutlinedIcon />,
      handleClick: swapStudentsMobileOrVR,
    },
  ];
  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      {
        name: `Estudiantes del curso ${courseName}`,
        href: '',
      },
    ],
    maxItems: 2,
  };
  if (courseId) {
    actions = actions.concat([
      {
        title: 'Importar estudiantes',
        icon: <AddIcon />,
        handleClick: () => navigate(`/courses/${courseId}/import_students`),
      },
      {},
    ]);
  }

  function sortDataByPath(path, direction) {
    const sortedData = SortData(students, path, direction);
    setStudents(sortedData);
  }

  function handleSearch(option) {
    setSearchValue(option.target.value);
  }

  const changeMobileOrVR = async (e, id, index) => {
    const value = e.target.value === 'Ambas' ? null : e.target.value;
    try {
      const response = await patchUserMobileOrVR(id, value);
      if (response.status === 200 || response.status === 201) {
        students[index].mobileOrVR = value;
        setStudents([...students]);
        alert('Se ha cambiado la habilitación de aplicaciones del estudiante exitosamente.');
      } else {
        alert(
          'Hubo un error al cambiar la habilitación de aplicaciones del estudiante. Intente de nuevo.'
        );
      }
    } catch {
      alert(
        'Hubo un error al cambiar la habilitación de aplicaciones del estudiante. Intente de nuevo.'
      );
    }
  };

  const handleDelete = async studentId => {
    const confirmation = window.confirm('¿Estás seguro que quieres eliminar este estudiante?');
    if (confirmation) {
      const response = await deleteUserFromCourse(studentId, courseId);
      if (response.status === 200) {
        alert('Estudiante eliminado con éxito.');
        window.location.reload();
      } else {
        alert('Error al eliminar al estudiante.');
      }
    }
  };

  useEffect(() => {
    if (courseId && studentsCourseData && !studentsCourseDataLoading) {
      setStudents(studentsCourseData.users);
      setPagination({
        currentPage: studentsCourseData.pagination.currentPage,
        itemsPerPage: itemsPerPage,
        totalPages: studentsCourseData.pagination.totalPages,
      });
      setLoading(false);
    } else if (studentsData && !studentsDataLoading) {
      setStudents(studentsData.users);
      setPagination({
        currentPage: studentsData.pagination.currentPage,
        itemsPerPage: itemsPerPage,
        totalPages: studentsData.pagination.totalPages,
      });
      setLoading(false);
    }
  }, [studentsCourseData, studentsCourseDataLoading, studentsData, studentsDataLoading]);

  useEffect(() => {
    getCurrentUser().then(user => {
      setRole(user.role);
    });
  }, []);

  return (
    <div className='antialiased font-sans w-full'>
      <div className='divide-y divide-primary'>
        <HeaderContainer
          breadcrumb={courseId ? itemsBreadcrumb : null}
          title={'Estudiantes'}
          search={{ handleSearch: handleSearch }}
          actions={actions}
        />
        <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader sortable onSort={direction => sortDataByPath('email', direction)}>
                  Correo
                </TableHeader>
                <TableHeader>ID estudiante</TableHeader>
                <TableHeader>Nombre/s</TableHeader>
                <TableHeader sortable onSort={direction => sortDataByPath('lastnames', direction)}>
                  Apellido/s
                </TableHeader>
                <TableHeader>Habilitación aplicaciones</TableHeader>
                {role === 'ADMIN' ? <TableHeader /> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {studentsCourseDataLoading || studentsDataLoading || loading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                students.map((student, index) =>
                  student.email.toLowerCase().includes(searchValue) ? (
                    <TableRow key={student.id}>
                      <TableCell>{student.email}</TableCell>
                      <TableCell>{student.student_id}</TableCell>
                      <TableCell>{student.firstnames}</TableCell>
                      <TableCell>{student.lastnames}</TableCell>
                      <TableCell>
                        <Select
                          name='role'
                          value={student.mobileOrVR === null ? 'Ambas' : student.mobileOrVR}
                          onChange={e => changeMobileOrVR(e, student.id, index)}
                          sx={{ minWidth: 96 }}
                          options={[
                            { value: 'Ambas', label: 'Ambas' },
                            { value: false, label: 'Móvil' },
                            { value: true, label: 'VR' },
                          ]}
                        />
                      </TableCell>
                      {role === 'ADMIN' ? (
                        <TableCell>
                          <TableButton variant={'delete'} onClick={() => handleDelete(student.id)}>
                            <DeleteOutlinedIcon color='red' />
                          </TableButton>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ) : null
                )
              )}
            </TableBody>
          </Table>
          <Pagination
            pagination={pagination}
            setPage={setPage}
            setLoading={setLoading}
            setItemPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentsTable;

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import { utils, writeFile } from 'xlsx';
import moment from 'moment/moment';
import * as Sentry from '@sentry/react';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import ImportStudentsTable from '../../style-components/Tables/ImportStudentsTable.jsx';
import Loading from '../../style-components/Loading.jsx';
import HeaderContainer from '../../style-components/HeaderContainer.jsx';
import FileUpload from '../../style-components/Form/FileUpload.jsx';
import { useGetCourse } from '../../models/course.js';
import { postUsers } from '../../models/user.js';
import { normalize } from '../../utils/normalize.js';
import { Typography } from '@mui/material';

const example = [
  {
    Correo: 'juanitaperez@uc.cl',
    'ID estudiante': '19624223',
    'Nombre/s': 'Juanita',
    'Apellido/s': 'Pérez',
    'Fecha de nacimiento': new Date('12-31-2000'),
    Género: 'FEMENINO',
    'País de procedencia': 'Chile',
    'Provincia/Comuna': 'Santiago',
    'Tipo de establecimiento': 'PUBLICO',
    'Vía de entrada': 'PSU, PTU O PAES',
    'Veces que ha cursado el curso': 1,
    'Carrera previa': 'INGENIERIA BIOMEDICA',
    'Habilitación aplicaciones': 'AMBAS',
  },
];

const help = [
  {
    Correo: 'Corresponde al email de cada estudiante. Debe ser único y válido.',
    'ID estudiante':
      'Corresponde al ID universitario único del estudiante. Debe ser sin caracteres especiales (solo números y letras).',
    'Nombre/s': 'Corresponde al nombre/s del estudiante.',
    'Apellido/s': 'Corresponde al apellido/s del estudiante.',
    'Fecha de nacimiento':
      'Correponde a la fecha de nacimiento del estudiante. Asegúrese que el campo del excel es de fecha.',
    Género:
      'Corresponde al género del estudiante. Debe ser FEMENINO, MASCULINO, OTRO o PREFIERO NO INFORMARLO.',
    'País de procedencia': 'Corresponde al país de procedencia del estudiante.',
    'Provincia/Comuna': 'Corresponde a la provincia o comuna de procedencia del estudiante.',
    'Tipo de establecimiento':
      'Corresponde al tipo de establecimiento de la educación media del estudiante. Debe ser PUBLICO, PRIVADO SUBVENCIONADO, PRIVADO u OTRO.',
    'Vía de entrada':
      'Corresponde a la vía de entrada del estudiante. Debe ser "PSU, PTU O PAES", "COLLEGE/BACHILLERATO", ACCESO DIRECTO EQUIDAD, CUPO ESPECIAL, SEGUNDO GRADO ACADEMICO, CICLOS FORMATIVOS DE SEGUNDO GRADO, u OTRO.',
    'Veces que ha cursado el curso':
      'Corresponde a la cantidad de veces que ha cursado el curso, incluyendo la actual.',
    'Carrera previa':
      'Corresponde a la carrera previa del estudiante. Debe ser NO TIENE, FISIOTERAPIA, ENFERMERIA, CIENCIAS BIOMEDICAS, INGENIERIA BIOMEDICA, BIOLOGIA, BIOQUIMICA u OTRA.',
    'Habilitación aplicaciones':
      'Corresponde a la habilitación de aplicaciones del estudiante. Debe ser AMBAS, MOVIL o VR. Este campo es opcional.',
  },
];

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const alphanumericRegex = /^[0-9a-zA-Z]+$/;

const meta_values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

const mobileOrVR = {
  AMBAS: null,
  MOVIL: false,
  VR: true,
};

const ImportStudents = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [students, setStudents] = useState([]);
  const [course] = useGetCourse(id);
  const [loading, setLoading] = useState(false);

  const actions = [{}];
  const itemsBreadcrumb = {
    items: [
      { name: 'Cursos', href: '/courses' },
      {
        name: 'Estudiantes',
        href: `/courses/${id}/my_students`,
      },
      {
        name: `Importar estudiantes al curso ${course?.name}`,
        href: '',
      },
    ],
    maxItems: 3,
  };

  const downloadTemplate = () => {
    try {
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(example);
      ws['!cols'] = Object.keys(example[0]).map(() => {
        return { wpx: 100 };
      });
      // ws['!rows'] = [null, { hpx: 200 }];
      utils.book_append_sheet(wb, ws, 'Plantilla');
      const wsHelp = utils.json_to_sheet(help);
      wsHelp['!cols'] = Object.keys(help[0]).map(() => {
        return { wpx: 100 };
      });
      // wsHelp['!rows'] = [null, { hpx: 200 }];
      utils.book_append_sheet(wb, wsHelp, 'Ayuda');
      writeFile(wb, 'Plantilla.Estudiantes.MedicineHub.xlsx', {
        type: 'array',
      });
    } catch (err) {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      alert('Ocurrió un error, intente de nuevo.');
    }
  };

  const handleFile = async file => {
    try {
      const fileStudents = await readXlsxFile(file);
      const tempStudents = [];
      for (let i = 1; i < fileStudents.length; i++) {
        tempStudents.push({
          email: fileStudents[i][0],
          student_id: fileStudents[i][1],
          firstnames: fileStudents[i][2],
          lastnames: fileStudents[i][3],
          birthday: fileStudents[i][4],
          gender: normalize(fileStudents[i][5]),
          country: normalize(fileStudents[i][6]),
          province: normalize(fileStudents[i][7]),
          establishment: normalize(fileStudents[i][8]),
          entry_way: normalize(fileStudents[i][9]),
          times_course_taken: fileStudents[i][10],
          previous_career: normalize(fileStudents[i][11]),
          mobileOrVR: fileStudents[i][12] ? normalize(fileStudents[i][12]) : null,
          university_id: course.university_id,
          role: 'STUDENT',
          meta_values,
        });
        if (!emailRegex.test(tempStudents[i - 1].email)) {
          alert(`El email del estudiante ${i} no es válido. Asegúrese que esté bien escrito.`);
          return;
        }
        if (!alphanumericRegex.test(tempStudents[i - 1].ID)) {
          alert(
            `El ID del estudiante ${i} no es válido. Asegúrese que solo contenga números y letras.`
          );
          return;
        }
        if (!moment(tempStudents[i - 1].birthday, 'YYYY/M/D', true).isValid()) {
          alert(
            `La fecha de nacimiento del estudiante ${i} no es válida. Asegúrese que el campo del excel es de fecha.`
          );
          return;
        }
        if (
          tempStudents[i - 1].gender !== 'FEMENINO' &&
          tempStudents[i - 1].gender !== 'MASCULINO' &&
          tempStudents[i - 1].gender !== 'OTRO' &&
          tempStudents[i - 1].gender !== 'PREFIERO NO INFORMARLO'
        ) {
          alert(
            `El género del estudiante ${i} no es válido. Asegúrese que sea FEMENINO, MASCULINO, OTRO o PREFIERO NO INFORMARLO.`
          );
          return;
        }
        if (
          tempStudents[i - 1].establishment !== 'PUBLICO' &&
          tempStudents[i - 1].establishment !== 'PRIVADO SUBVENCIONADO' &&
          tempStudents[i - 1].establishment !== 'PRIVADO' &&
          tempStudents[i - 1].establishment !== 'OTRO'
        ) {
          alert(
            `El tipo de establecimiento del estudiante ${i} no es válido. Asegúrese que sea PUBLICO, PRIVADO SUBVENCIONADO, PRIVADO u OTRO.`
          );
          return;
        }
        if (
          tempStudents[i - 1].entry_way !== 'PSU, PTU O PAES' &&
          tempStudents[i - 1].entry_way !== 'COLLEGE/BACHILLERATO' &&
          tempStudents[i - 1].entry_way !== 'ACCESO DIRECTO EQUIDAD' &&
          tempStudents[i - 1].entry_way !== 'CUPO ESPECIAL' &&
          tempStudents[i - 1].entry_way !== 'SEGUNDO GRADO ACADEMICO' &&
          tempStudents[i - 1].entry_way !== 'CICLOS FORMATIVOS DE SEGUNDO GRADO' &&
          tempStudents[i - 1].entry_way !== 'OTRO'
        ) {
          alert(
            `La forma de ingreso del estudiante ${i} no es válida. Asegúrese que sea "PSU, PTU O PAES", "COLLEGE/BACHILLERATO", ACCESO DIRECTO EQUIDAD, CUPO ESPECIAL, SEGUNDO GRADO ACADEMICO, CICLOS FORMATIVOS DE SEGUNDO GRADO, u OTRO.`
          );
          return;
        }
        if (
          tempStudents[i - 1].times_course_taken === '' ||
          isNaN(tempStudents[i - 1].times_course_taken) ||
          !Number.isInteger(Number(tempStudents[i - 1].times_course_taken)) ||
          Number(tempStudents[i - 1].times_course_taken) < 1
        ) {
          alert(
            `El número de veces que el estudiante ${i} ha tomado el curso no es válido. Debe ser un número entero mayor a 0.`
          );
          return;
        }
        if (
          tempStudents[i - 1].previous_career !== 'NO TIENE' &&
          tempStudents[i - 1].previous_career !== 'FISIOTERAPIA' &&
          tempStudents[i - 1].previous_career !== 'ENFERMERIA' &&
          tempStudents[i - 1].previous_career !== 'CIENCIAS BIOMEDICAS' &&
          tempStudents[i - 1].previous_career !== 'INGENIERIA BIOMEDICA' &&
          tempStudents[i - 1].previous_career !== 'BIOLOGIA' &&
          tempStudents[i - 1].previous_career !== 'BIOQUIMICA' &&
          tempStudents[i - 1].previous_career !== 'OTRO'
        ) {
          alert(
            `La carrera previa del estudiante ${i} no es válida. Debe ser NO TIENE, FISIOTERAPIA, ENFERMERIA, CIENCIAS BIOMEDICAS, INGENIERIA BIOMEDICA, BIOLOGIA, BIOQUIMICA u OTRO.`
          );
          return;
        }
        if (
          tempStudents[i - 1].mobileOrVR !== 'AMBAS' &&
          tempStudents[i - 1].mobileOrVR !== 'MOVIL' &&
          tempStudents[i - 1].mobileOrVR !== 'VR' &&
          tempStudents[i - 1].mobileOrVR !== null
        ) {
          alert(
            `La habilitación de las aplicaciones del estudiante ${i} no es válida. Debe ser AMBAS, MOVIL o VR.`
          );
          return;
        } else if (tempStudents[i - 1].mobileOrVR) {
          tempStudents[i - 1].mobileOrVR = mobileOrVR[tempStudents[i - 1].mobileOrVR];
        }
      }
      setStudents(tempStudents);
    } catch (err) {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      alert('Error al leer el archivo, asegúrese que las columnas están en el orden correcto.');
    }
  };

  const importStudents = async () => {
    setLoading(true);
    try {
      // post students
      const response = await postUsers(students, course.id);
      for (let i = 0; i < response.length; i++) {
        if (!response[i].userConfirmed) {
          setLoading(false);
          alert(
            'Ocurrió un error al importar algunos estudiantes. Revise los que se importaron e intente de nuevo.'
          );
          return;
        }
      }
      alert('Los estudiantes se importaron correctamente.');
      navigate(-1);
    } catch (err) {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      alert('Ocurrió un error, intente de nuevo.');
    }
    setLoading(false);
  };

  return (
    <BoxContainer>
      <div className='antialiased font-sans w-full'>
        <div className='divide-y divide-primary'>
          <HeaderContainer breadcrumb={itemsBreadcrumb} actions={actions} />
          <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
            <Button
              sx={{ mb: 4 }}
              onClick={downloadTemplate}
              startIcon={<FileDownloadOutlinedIcon />}
              variant='contained'>
              Descargar plantilla (modelo)
            </Button>
            <Typography sx={{ mb: 4 }} variant='body1'>
              Ocupa la plantilla (modelo) para subir de manera correcta los campos de los
              estudiantes. En la plantilla hay 1 estudiante de ejemplo y en la hoja "Ayuda" está la
              explicación y las opciones a colocar en cada columna.
              <br />
              <b>NO MODIFICAR EL ORDEN DE LAS COLUMNAS.</b>
            </Typography>
            <FileUpload
              label='Elegir archivo'
              onChange={e => handleFile(e.target.files[0])}
              accept='.xlsx'
              startIcon={<FileUploadOutlinedIcon />}
            />
          </div>
          {students.length > 0 ? (
            <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
              <Button
                sx={{ mb: 2 }}
                onClick={importStudents}
                startIcon={<CloudUploadOutlinedIcon />}
                variant='contained'
                color='secondary'>
                Importar estudiantes
              </Button>
              <ImportStudentsTable students={students} />
            </div>
          ) : null}
          {loading ? <Loading /> : null}
        </div>
      </div>
    </BoxContainer>
  );
};

export default ImportStudents;

import React from 'react';
import { useParams } from 'react-router-dom';

import LearningUnitList from './LearningUnitList.jsx';
import { useGetCourse } from '../../models/course.js';
import Loading from '../../style-components/Loading.jsx';

function LearningUnits() {
  const { id } = useParams();
  const [course, loadingCourse] = useGetCourse(id);

  return (
    <div className='flex justify-between bg-slate-50 h-screen'>
      <div className='flex flex-col w-full'>
        {loadingCourse ? <Loading /> : <LearningUnitList course={course} />}
      </div>
    </div>
  );
}

export default LearningUnits;

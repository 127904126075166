import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

import { getCurrentUser } from '../../models/user.js';
import { getUniversity } from '../../models/universities.js';
import Loading from '../../style-components/Loading.jsx';
import BoxContainer from '../../style-components/BoxContainer.jsx';

const role = {
  ADMIN: 'Administrador',
  TEACHER: 'Profesor',
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  text: {
    margin: 12,
  },
};

const ProfileView = () => {
  const [university, setUniversity] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    getCurrentUser()
      .then(data => {
        setUser(data);
        getUniversity(data?.university_id).then(university => {
          setUniversity(university?.name);
        });
      })
      .catch();
  }, []);

  return (
    <BoxContainer>
      <Typography style={styles.text} variant='h4'>
        Mi perfil
      </Typography>
      <div>
        {user ? (
          <div style={styles.container}>
            <div style={styles.columnContainer}>
              <Typography style={styles.text}>
                <strong>Email: </strong>
                {user?.email}
              </Typography>
              <Typography style={styles.text}>
                <strong>Nombre/s: </strong>
                {user?.firstnames}
              </Typography>
              <Typography style={styles.text}>
                <strong>Apellido/s: </strong>
                {user?.lastnames}
              </Typography>
            </div>

            <div style={styles.columnContainer}>
              <Typography style={styles.text}>
                <strong>Rol: </strong>
                {role[user?.role]}
              </Typography>
              <Typography style={styles.text}>
                <strong>Fecha de nacimiento: </strong>
                {user?.birthday}
              </Typography>
              <Typography style={styles.text}>
                <strong>Género: </strong>
                {user?.gender}
              </Typography>
            </div>

            <div style={styles.columnContainer}>
              <Typography style={styles.text}>
                <strong>País: </strong>
                {user?.country}
              </Typography>
              <Typography style={styles.text}>
                <strong>Provincia/Comuna: </strong>
                {user?.province}
              </Typography>
              <Typography style={styles.text}>
                <strong>Universidad: </strong>
                {university}
              </Typography>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </BoxContainer>
  );
};

export default ProfileView;

import React from 'react';
import { useParams } from 'react-router-dom';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import StudentsTable from '../../style-components/Tables/StudentsTable.jsx';
import { useGetCourse } from '../../models/course.js';

function Students() {
  const { id } = useParams();
  const [course] = useGetCourse(id);

  return (
    <BoxContainer>
      <StudentsTable courseId={id} courseName={course?.name} />
    </BoxContainer>
  );
}

export default Students;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';

import { signIn } from '../../utils/cognitoAuth';
import logoVertical from '../../assets/images/LOGO_with_letters_vertical_white.svg';
import backgroundImage from '../../assets/images/IMG_BACKGROUND_LOGIN.png';

function LogIn() {
  const navigate = useNavigate();
  const theme = useTheme();

  const styles = {
    backgroundImageStyle: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100vh',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '100px',
    },
    image: { width: '240px', marginBottom: '20px' },
    input: {
      backgroundColor: theme.palette.white.main,
    },
    button: {
      '&:hover': {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
    },
  };

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await signIn(loginEmail, loginPassword);
      navigate('/index');
    } catch (error) {
      setError('Usuario o contraseña inválidos');
      Sentry.captureException(error);
    }
    setLoading(false);
  };

  return (
    <div style={styles.backgroundImageStyle}>
      <Container sx={styles.container}>
        <img src={logoVertical} alt='Logo' style={styles.image} />
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            ...styles.container,
          }}>
          <FormControl sx={{ m: 1, width: '25ch' }} variant='outlined'>
            {loginEmail ? null : (
              <InputLabel htmlFor='user' color='black'>
                Usuario
              </InputLabel>
            )}
            <OutlinedInput
              id='user'
              label={loginEmail ? null : 'Usuario'}
              sx={styles.input}
              error={error ? true : false}
              value={loginEmail}
              onChange={event => {
                setLoginEmail(event.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '25ch' }} variant='outlined'>
            {loginPassword ? null : (
              <InputLabel htmlFor='password' color='black'>
                Contraseña
              </InputLabel>
            )}
            <OutlinedInput
              id='password'
              label={loginPassword ? null : 'Contraseña'}
              sx={styles.input}
              error={error ? true : false}
              type={showPassword ? 'text' : 'password'}
              value={loginPassword}
              onChange={event => {
                setLoginPassword(event.target.value);
              }}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'>
                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormHelperText error id='outlined-helper'>
            {error}
          </FormHelperText>
          <Button
            variant='contained'
            sx={styles.button}
            onClick={handleLogin}
            color='success'
            disabled={loading}>
            {loading ? 'Cargando...' : 'INICIAR SESIÓN'}
          </Button>
        </Box>
      </Container>
    </div>
  );
}

export default LogIn;

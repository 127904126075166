import React from 'react';
import useTheme from '@mui/material/styles/useTheme';

const BoxContainer = ({ children }) => {
  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '20px 20px',
    },
    box: {
      borderWidth: '1.5px',
      borderColor: theme.palette.primary.main,
      borderStyle: 'solid',
      borderRadius: '10px',
      backgroundColor: theme.palette.white.main,
      width: '100%',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        {children}
      </div>
    </div>
  );
};

export default BoxContainer;

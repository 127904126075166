import { useAsync } from 'react-use';
import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

// GET all questions
export function useGetTerms() {
  const { value, loading, error } = useAsync(() => getTerms(), []);
  return [value, loading, error];
}

export const getTerms = async () => {
  const token = await authorizationHeader();
  const questions = await api
    .get(`/terms/informed-consent`, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return questions.data;
};

export const patchTerms = async terms => {
  const token = await authorizationHeader();
  const response = await api
    .patch(`/terms/informed-consent`, terms, {
      headers: token,
    })
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return response;
};

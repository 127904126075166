import React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default function DatePickerComponent({
  value,
  setFormState,
  name,
  label = '',
  minDate = null,
  maxDate = null,
  type = 'date',
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {type === 'date' ? (
        <DatePicker
          label={label}
          value={dayjs(value)}
          onChange={newValue =>
            setFormState(prev => ({ ...prev, [name]: newValue.toISOString().split('T')[0] }))
          }
          minDate={minDate ? dayjs(minDate) : null}
          maxDate={maxDate ? dayjs(maxDate) : null}
          timezone='UTC'
        />
      ) : (
        <DateTimePicker
          label={label}
          value={dayjs(value)}
          onChange={newValue => setFormState(prev => ({ ...prev, [name]: newValue.toISOString() }))}
          minDateTime={minDate ? dayjs(minDate) : null}
          maxDateTime={maxDate ? dayjs(maxDate) : null}
          timezone='UTC'
        />
      )}
    </LocalizationProvider>
  );
}

import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';
import { Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Loading from '../style-components/Loading.jsx';
import Header from './NavBar/Header.jsx';
import LogIn from './PreLogin/LogIn.jsx';
import { ChangePassword } from './auth/index.js';
import { getCurrentUser } from '../utils/cognitoAuth.js';

export default function AuthHub() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const user = () => {
    setLoading(true);
    getCurrentUser()
      .then(data => {
        setCurrentUser(data);
        setLoading(false);
      })
      .catch(err => {
        Sentry.captureException(err);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      });
  };

  useEffect(() => {
    user();

    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signUp':
          break;
        case 'cognitoHostedUI':
          break;
        case 'signIn':
          setLoading(true);
          getCurrentUser()
            .then(userData => {
              setCurrentUser(userData);
              setLoading(false);
            })
            .catch(err => {
              Sentry.captureException(err);
              setLoading(false);
            });
          break;
        case 'signOut':
          setCurrentUser(null);
          break;
        case 'signIn_failure':
          break;
        case 'cognitoHostedUI_failure':
          break;
        default:
      }
    });
  }, []);

  return (
    <div style={{ backgroundColor: '#f1f6ff' }} className='flex flex-col justify-center'>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!currentUser && <LogIn />}
          {currentUser && <Header component={<Outlet />} />}
          {(!currentUser || !currentUser.email_verified) && <></>}
          {currentUser && currentUser.identities && <ChangePassword />}
        </>
      )}
    </div>
  );
}

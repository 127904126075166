import * as Sentry from '@sentry/react';

import api from '../api/axios';
import authorizationHeader from '../api/token';

export const questionGenerateExplain = async id => {
  const token = await authorizationHeader();
  const question = await api
    .patch(
      `/MediBot/questions/generate-explain/${id}`,
      {},
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return question.data;
};

export const questionGenerateTips = async id => {
  const token = await authorizationHeader();
  const question = await api
    .patch(
      `/MediBot/questions/generate-tips/${id}`,
      {},
      {
        headers: token,
      }
    )
    .then(res => res)
    .catch(err => {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      return err;
    });
  return question.data;
};

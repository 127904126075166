import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import { utils, writeFile } from 'xlsx';
import * as Sentry from '@sentry/react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import BoxContainer from '../../style-components/BoxContainer.jsx';
import HeaderContainer from '../../style-components/HeaderContainer.jsx';
import Select from '../../style-components/Form/Select.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import FileUpload from '../../style-components/Form/FileUpload.jsx';
import ImportQuestionsTable from '../../style-components/Tables/ImportQuestionsTable.jsx';
import Loading from '../../style-components/Loading.jsx';
import { useGetLearnunits, postLearnunit } from '../../models/learnunits.js';
import { useGetEvaluation } from '../../models/evaluations.js';
import { postImagesUpload, postImages } from '../../models/images.js';
import { postBulkQuestions, postBulkQuestionsWithEvaluation } from '../../models/questions.js';
import { getCurrentUser, getUsersWithPagination } from '../../models/user.js';
import { normalize } from '../../utils/normalize.js';

const styles = {
  learunitInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '1rem',
  },
};

const correctAnswer = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
  F: 0,
  V: 1,
};

const example = [
  {
    Pregunta: 'Esta es una pregunta de ALTERNATIVAS',
    'Tipo de pregunta': 'ALTERNATIVAS',
    Dificultad: 'FACIL',
    Dominio: 'IDENTIFICAR',
    Puntaje: 100,
    'Respuesta correcta': 'A',
    'Ejemplo respuesta correcta': '',
    'Alternativa A': 'Contenido de la alternativa A',
    'Alternativa B': 'Contenido de la alternativa B',
    'Alternativa C': 'Contenido de la alternativa C',
    'Alternativa D': 'Contenido de la alternativa D',
    Tiempo: 180,
    Pistas: 'Esta es una pista de ayuda.;Esta es otra pista de ayuda.',
    'Explicación pregunta respuestas':
      'Texto descriptivo de la explicación de la pregunta con sus respuestas detalladas de las ALTERNATIVAS.',
    'Nombre imagen del banco de imágenes': 'NombreDeLaImagen',
    'Categoría 1': '',
    'Categoría 2': '',
    Mesa: 1,
    Orden: 1,
  },
  {
    Pregunta: 'Esta es una pregunta de V/F',
    'Tipo de pregunta': 'V/F',
    Dificultad: 'MEDIA',
    Dominio: 'COMPRENDER',
    Puntaje: '',
    'Respuesta correcta': 'V',
    'Ejemplo respuesta correcta': '',
    'Alternativa A': '',
    'Alternativa B': '',
    'Alternativa C': '',
    'Alternativa D': '',
    Tiempo: '',
    Pistas: '',
    'Explicación pregunta respuestas':
      'Texto descriptivo de la explicación de la pregunta con sus respuestas detalladas del V/F.',
    'Nombre imagen del banco de imágenes': '',
    'Categoría 1': '',
    'Categoría 2': '',
    Mesa: 1,
    Orden: 2,
  },
  {
    Pregunta: 'Esta es una pregunta de DESARROLLO',
    'Tipo de pregunta': 'DESARROLLO',
    Dificultad: 'DIFICIL',
    Dominio: 'APLICAR',
    Puntaje: 300,
    'Respuesta correcta': 'PALABRAS;CLAVES;CORRECTAS',
    'Ejemplo respuesta correcta': 'Texto ejemplo respuesta correcta solo cuando es DESARROLLO',
    'Alternativa A': '',
    'Alternativa B': '',
    'Alternativa C': '',
    'Alternativa D': '',
    Tiempo: 300,
    Pistas: 'Pista de ayuda.',
    'Explicación pregunta respuestas': '',
    'Nombre imagen del banco de imágenes': 'NombreDeImagenDesarrollo',
    'Categoría 1': '',
    'Categoría 2': '',
    Mesa: '',
    Orden: '',
  },
];

const help = [
  {
    Pregunta: 'Contenido de la pregunta.',
    'Tipo de pregunta':
      'Corresponde al tipo de pregunta. Puede ser: ALTERNATIVAS, V/F o DESARROLLO.',
    Dificultad: 'Corresponde a la dificultad de la pregunta. Puede ser: FACIL, MEDIA o DIFICIL.',
    Dominio:
      'Corresponde al dominio de la pregunta. Puede ser: IDENTIFICAR, COMPRENDER, APLICAR o ANALIZAR.',
    Puntaje:
      'Corresponde al puntaje de la pregunta al tenerla correcta. Sirve para ganar medallas y el ranking dentro de la aplicación móvil. El puntaje se debe asociar al dominio y a la dificultad de la pregunta. Este campo es opcional.',
    'Respuesta correcta':
      'Corresponde a la respuesta correcta de la pregunta. Para ALTERNATIVAS puede ser: A, B, C o D. Para V/F puede ser: V o F. Para DESARROLLO debe ser una o más palabras claves separadas por el símbolo ; que representen la respuesta correcta.',
    'Ejemplo respuesta correcta':
      'Corresponde al texto  que se le entrega al estudiante cuando este se equivoca o cuando necesita enteder al final de la evaluación una orientacion de la respuesta correcta de DESARROLLO. Este campo es opcional.',
    'Alternativa A':
      'Corresponde al contenido de la alternativa A. Este campo solo se rellena en el caso que el tipo de pregunta sea ALTERNATIVAS.',
    'Alternativa B':
      'Corresponde al contenido de la alternativa B. Este campo solo se rellena en el caso que el tipo de pregunta sea ALTERNATIVAS.',
    'Alternativa C':
      'Corresponde al contenido de la alternativa C. Este campo solo se rellena en el caso que el tipo de pregunta sea ALTERNATIVAS.',
    'Alternativa D':
      'Corresponde al contenido de la alternativa D. Este campo solo se rellena en el caso que el tipo de pregunta sea ALTERNATIVAS.',
    Tiempo:
      'Corresponde al tiempo en segundos que tienen los estudiantes para responder la pregunta. Acabado el tiempo pueden seguir respondiendo, pero con una penalidad en el puntaje. Este campo es opcional.',
    Pistas:
      'Corresponde a las pistas que se le entrega al estudiante cuando este se equivoca o cuando necesita ayuda. Las pistas deben ir separadas por el símbolo ; Este campo es opcional.',
    'Explicación pregunta respuestas':
      'Corresponde al texto que se le entrega al estudiante cuando este se equivoca o cuando necesita enteder al final de la evaluación una orientacion de las respuestas correctas o no. Este campo es opcional.',
    'Nombre imagen del banco de imágenes':
      'Corresponde al nombre exacto de una imagen previamente subida al banco de imágenes. Si la pregunta no tiene imagen asociada o la imagen todavía no se sube al banco de imágenes se debe dejar el campo en blanco.',
    'Categoría 1':
      'Corresponde a la primera categorización: Anatomía Descriptiva o Anatomía Topográfica. Este campo es opcional.',
    'Categoría 2':
      'Corresponde a la sub categoría o unidad de aprendizaje. Este campo es opcional.',
    Mesa: 'Corresponde al número de mesa en la que se debe responder la pregunta. Este campo es opcional.',
    Orden:
      'Corresponde al orden en el que se debe responder la pregunta dentro de la mesa. Este campo es opcional.',
  },
];

const ImportQuestions = () => {
  const navigate = useNavigate();
  const { id, unit_id, eval_id } = useParams();

  const [questions, setQuestions] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [learnunitsData, learnunitsDataLoading] = useGetLearnunits();
  const [learnunits, setLearnunits] = useState([]);
  const [selectedLearnunit, setSelectedLearnunit] = useState('');
  const [evaluation] = useGetEvaluation(eval_id);
  const [newLearnunit, setNewLearnunit] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [role, setRole] = useState('TEACHER');
  const [ownerId, setOwnerId] = useState('');

  const actions = [{}];
  const itemsBreadcrumb = {
    maxItems: 5,
  };
  if (eval_id) {
    itemsBreadcrumb.items = [
      { name: 'Cursos', href: '/courses' },
      {
        name: 'Unidades de Aprendizaje',
        href: `/courses/${id}/learning_units`,
      },
      {
        name: 'Evaluaciones',
        href: `/courses/${id}/${unit_id}/evaluations`,
      },
      {
        name: 'Preguntas',
        href: `/courses/${id}/${unit_id}/evaluations/${eval_id}/question`,
      },
      {
        name: `Importar preguntas a la evaluación ${evaluation?.name}`,
        href: '',
      },
    ];
  } else {
    itemsBreadcrumb.items = [
      { name: 'Preguntas', href: '/questions' },
      { name: 'Importar preguntas', href: '' },
    ];
  }

  useEffect(() => {
    getCurrentUser().then(currentUser => {
      setRole(currentUser.role);
      if (currentUser.role === 'ADMIN') {
        getUsersWithPagination(1, 9999, 'TEACHER').then(res =>
          setTeachers(
            res.users.map(user => ({
              value: user.id,
              label: `${user.firstnames} ${user.lastnames}`,
            }))
          )
        );
      } else if (currentUser.role === 'TEACHER') {
        setOwnerId(currentUser.id);
      }
    });
  }, []);

  useEffect(() => {
    if (learnunitsData && !learnunitsDataLoading) {
      setLearnunits(
        learnunitsData.data?.map(learnunit => ({ value: learnunit.id, label: learnunit.name })) ||
          []
      );
      if (unit_id) {
        setSelectedLearnunit(unit_id);
      }
    }
  }, [learnunitsData, learnunitsDataLoading]);

  const downloadTemplate = () => {
    try {
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(example);
      ws['!cols'] = Object.keys(example[0]).map(() => {
        return { wpx: 100 };
      });
      // ws['!rows'] = [null, { hpx: 200 }];
      utils.book_append_sheet(wb, ws, 'Preguntas');
      const wsHelp = utils.json_to_sheet(help);
      wsHelp['!cols'] = Object.keys(help[0]).map(() => {
        return { wpx: 100 };
      });
      // wsHelp['!rows'] = [null, { hpx: 200 }];
      utils.book_append_sheet(wb, wsHelp, 'Guía');
      writeFile(wb, 'Plantilla.Preguntas.MedicineHub.xlsx', {
        type: 'array',
      });
    } catch (err) {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      alert('Ocurrió un error, intente de nuevo.');
    }
  };

  const handleFile = async file => {
    try {
      const checkTableOrder = {};
      const fileQuestions = await readXlsxFile(file);
      const tempQuestions = [];
      // Pregunta	                       | Tipo de pregunta | Dificultad	  | Dominio	                 | Puntaje       | Respuesta correcta |
      // Ejemplo respuesta correcta	     | Alternativa A	  | Alternativa B | Alternativa C	           | Alternativa D | Tiempo	            | Pistas
      // Explicación pregunta respuestas | Nombre banco de imágenes | Categoría 1	    | Categoría 2    | Mesa          | Orden
      for (let i = 1; i < fileQuestions.length; i++) {
        if (!fileQuestions[i][0]) {
          alert(`La pregunta ${i + 1} no tiene contenido y es obligatorio.`);
          return;
        }
        if (!fileQuestions[i][1]) {
          alert(`La pregunta ${i + 1} no tiene tipo de pregunta y es obligatorio.`);
          return;
        }
        if (!fileQuestions[i][2]) {
          fileQuestions[i][2] = '';
        }
        if (!fileQuestions[i][3]) {
          fileQuestions[i][3] = '';
        }
        tempQuestions.push({
          content: fileQuestions[i][0].toString(),
          question_type: normalize(fileQuestions[i][1]),
          difficulty: normalize(fileQuestions[i][2]),
          domain: normalize(fileQuestions[i][3]),
          total_points: fileQuestions[i][4], // optional
          correct_answer: fileQuestions[i][5].toString(),
          example_correct_answer: fileQuestions[i][6], // optional
          possible_answers: [
            fileQuestions[i][7],
            fileQuestions[i][8],
            fileQuestions[i][9],
            fileQuestions[i][10],
          ],
          time_to_answer: fileQuestions[i][11], // optional
          tips: fileQuestions[i][12], // optional
          explanation_question_answers: fileQuestions[i][13], // optional
          image_id: fileQuestions[i][14], // optional
          table: fileQuestions[i][17], // optional
          order: fileQuestions[i][18], // optional
        });

        if (
          tempQuestions[i - 1].question_type !== 'ALTERNATIVAS' &&
          tempQuestions[i - 1].question_type !== 'V/F' &&
          tempQuestions[i - 1].question_type !== 'DESARROLLO'
        ) {
          alert(
            `El tipo de pregunta de la pregunta ${i} no es válido. Revisa que todas las preguntas tengan ALTERNATIVAS, V/F o DESARROLLO.`
          );
          return;
        }
        if (tempQuestions[i - 1].content === '') {
          alert(`El contenido de la pregunta ${i} no puede estar vacío.`);
          return;
        }
        if (
          tempQuestions[i - 1].question_type === 'ALTERNATIVAS' &&
          tempQuestions[i - 1].correct_answer !== 'A' &&
          tempQuestions[i - 1].correct_answer !== 'B' &&
          tempQuestions[i - 1].correct_answer !== 'C' &&
          tempQuestions[i - 1].correct_answer !== 'D'
        ) {
          alert(
            `La respuesta correcta de la pregunta ${i} no es válida. Revisa que sea A, B, C o D para preguntas de tipo ALTERNATIVAS.`
          );
          return;
        } else if (
          tempQuestions[i - 1].question_type === 'V/F' &&
          tempQuestions[i - 1].correct_answer !== 'V' &&
          tempQuestions[i - 1].correct_answer !== 'F'
        ) {
          alert(
            `La respuesta correcta de la pregunta ${i} no es válida. Revisa que sea V o F para preguntas de tipo V/F.`
          );
          return;
        } else if (!tempQuestions[i - 1].correct_answer) {
          alert(
            `La respuesta correcta de la pregunta ${i} no es válida. Revisa que existan palabras claves y estas separadas por ; para preguntas de tipo DESARROLLO.`
          );
          return;
        } else if (tempQuestions[i - 1].question_type === 'DESARROLLO') {
          tempQuestions[i - 1].correct_answer = tempQuestions[i - 1].correct_answer
            .split(';')
            .join(' | ');
        }
        if (
          tempQuestions[i - 1].domain !== 'IDENTIFICAR' &&
          tempQuestions[i - 1].domain !== 'COMPRENDER' &&
          tempQuestions[i - 1].domain !== 'APLICAR' &&
          tempQuestions[i - 1].domain !== 'ANALIZAR' &&
          tempQuestions[i - 1].domain !== ''
        ) {
          alert(
            `El dominio de la pregunta ${i} no es válido. Revisa que sea IDENTIFICAR, COMPRENDER, APLICAR O ANALIZAR.`
          );
          return;
        }
        if (
          tempQuestions[i - 1].difficulty !== 'FACIL' &&
          tempQuestions[i - 1].difficulty !== 'MEDIA' &&
          tempQuestions[i - 1].difficulty !== 'DIFICIL' &&
          tempQuestions[i - 1].difficulty !== ''
        ) {
          alert(
            `La dificultad de la pregunta ${i} no es válida. Revisa que sea FACIL, MEDIA o DIFICIL.`
          );
          return;
        }
        // total points num
        if (
          (tempQuestions[i - 1].total_points &&
            !isNaN(tempQuestions[i - 1].total_points) &&
            Number.isInteger(Number(tempQuestions[i - 1].total_points)) &&
            Number(tempQuestions[i - 1].total_points) < 0) ||
          (tempQuestions[i - 1].total_points && isNaN(tempQuestions[i - 1].total_points))
        ) {
          alert(
            `El puntaje de la pregunta ${i} no es válido. Revisa que sea un número entero positivo.`
          );
          return;
        }
        if (!tempQuestions[i - 1].total_points) {
          tempQuestions[i - 1].total_points = 100;
        }
        // time to answer num
        if (
          (tempQuestions[i - 1].time_to_answer &&
            !isNaN(tempQuestions[i - 1].time_to_answer) &&
            Number.isInteger(Number(tempQuestions[i - 1].time_to_answer)) &&
            Number(tempQuestions[i - 1].time_to_answer) < 0) ||
          (tempQuestions[i - 1].time_to_answer && isNaN(tempQuestions[i - 1].time_to_answer))
        ) {
          alert(
            `El tiempo de la pregunta ${i} no es válido. Revisa que sea un número entero positivo.`
          );
          return;
        }
        // check table is correct
        if (
          (tempQuestions[i - 1].table &&
            !isNaN(tempQuestions[i - 1].table) &&
            Number.isInteger(Number(tempQuestions[i - 1].table)) &&
            Number(tempQuestions[i - 1].table) < 1) ||
          (tempQuestions[i - 1].table && isNaN(tempQuestions[i - 1].table))
        ) {
          alert(
            `El número de mesa de la pregunta ${i} no es válido. Revisa que sea un número entero positivo.`
          );
          return;
        }
        // check order is correct
        if (
          (tempQuestions[i - 1].order &&
            !isNaN(tempQuestions[i - 1].order) &&
            Number.isInteger(Number(tempQuestions[i - 1].order)) &&
            Number(tempQuestions[i - 1].order) < 1) ||
          (tempQuestions[i - 1].order && isNaN(tempQuestions[i - 1].order))
        ) {
          alert(
            `El orden de la pregunta ${i} no es válido. Revisa que sea un número entero positivo.`
          );
          return;
        }
        if (
          (tempQuestions[i - 1].table && !tempQuestions[i - 1].order) ||
          (!tempQuestions[i - 1].table && tempQuestions[i - 1].order)
        ) {
          alert(
            `El orden y la mesa de la pregunta ${i} deben estar ambos completos o ambos vacíos.`
          );
          return;
        }
        if (tempQuestions[i - 1].table && tempQuestions[i - 1].order) {
          if (!checkTableOrder[tempQuestions[i - 1].table]) {
            checkTableOrder[tempQuestions[i - 1].table] = { [tempQuestions[i - 1].order]: true };
          } else {
            if (checkTableOrder[tempQuestions[i - 1].table][tempQuestions[i - 1].order]) {
              alert(
                `El orden y la mesa de la pregunta ${i} ya fueron usados. Revisa que sean únicos.`
              );
              return;
            } else {
              checkTableOrder[tempQuestions[i - 1].table][tempQuestions[i - 1].order] = true;
            }
          }
        }
        if (tempQuestions[i - 1].tips) {
          tempQuestions[i - 1].tips = tempQuestions[i - 1].tips.toString().split(';').join(' | ');
        }
      }
      setQuestions(tempQuestions);
      setImages(
        tempQuestions.map(question => ({
          details: question.image_id,
          file: null,
          type: null,
          image_type: null,
        }))
      );
    } catch (err) {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      alert(
        'Error al leer el archivo, asegúrese que las columnas están en el orden correcto y que todos los campos obligatorios están completos.'
      );
    }
  };

  const importQuestions = async () => {
    setLoading(true);
    try {
      if (role === 'ADMIN' && ownerId === '') {
        alert('Debe seleccionar un profesor.');
        setLoading(false);
        return;
      }
      // check learnunit
      let learnunit_id;
      if (unit_id && unit_id !== '0') {
        learnunit_id = unit_id;
      } else if (selectedLearnunit !== '') {
        learnunit_id = selectedLearnunit;
      } else if (newLearnunit !== '') {
        let duplicate = false;
        learnunits.data.forEach(learnunit => {
          if (normalize(learnunit.label) === normalize(newLearnunit)) {
            learnunit_id = learnunit.value;
            duplicate = true;
          }
        });
        if (!duplicate) {
          const res = await postLearnunit({ name: newLearnunit });
          learnunit_id = res.data[0].id;
        }
      } else {
        alert('Debe seleccionar una unidad de aprendizaje.');
        setLoading(false);
        return;
      }
      // ready images to upload to S3
      const imagesIndexToFile = {};
      const imagesToUpload = [];
      let counter = 0;
      for (let i = 0; i < images.length; i++) {
        if (images[i].type === 'file') {
          imagesToUpload.push(images[i].file);
          imagesIndexToFile[i] = counter;
          counter++;
        }
      }

      // upload images to S3
      let imagesPostRes = [];
      if (imagesToUpload.length > 0) {
        const response = await postImagesUpload(imagesToUpload);
        if (!(response.status === 200 || response.status === 201)) {
          alert('Error al subir las imágenes. Intente de nuevo.');
          setLoading(false);
          return;
        }
        const imagesUploadRes = response.data;

        // post images to database
        const imagesToPost = [];
        for (let i = 0; i < images.length; i++) {
          if (images[i].type === 'file') {
            imagesToPost.push({
              details: images[i].details,
              type: images[i].image_type,
              owner_id: ownerId,
              url: imagesUploadRes[imagesIndexToFile[i]].url,
              bucketPath: imagesUploadRes[imagesIndexToFile[i]].bucketPath,
              urlExpiracyDatetime: imagesUploadRes[imagesIndexToFile[i]].urlExpiracyDatetime,
              learnunit_id,
              share: true,
            });
          }
        }
        imagesPostRes = await postImages(imagesToPost);
      }

      // add image_id to questions / prepare questions to post
      const questionsToPost = [];
      for (let i = 0; i < questions.length; i++) {
        questionsToPost.push({
          ...questions[i],
          tips: questions[i].tips ? questions[i].tips.split(' | ') : null,
          learnunit_id,
          share: true,
          owner_id: ownerId,
        });

        if (questionsToPost[i].question_type === 'DESARROLLO') {
          questionsToPost[i].possible_answers = questionsToPost[i].correct_answer.split(' | ');
          questionsToPost[i].correct_answer = null;
        } else {
          questionsToPost[i].correct_answer = correctAnswer[questionsToPost[i].correct_answer];
          if (questionsToPost[i].question_type === 'V/F') {
            questionsToPost[i].possible_answers = null;
          }
        }

        if (questionsToPost[i].total_points === '') {
          questionsToPost[i].total_points = null;
        } else {
          questionsToPost[i].total_points = parseInt(questionsToPost[i].total_points, 10);
        }

        if (questionsToPost[i].time_to_answer === '') {
          questionsToPost[i].time_to_answer = null;
        } else {
          questionsToPost[i].time_to_answer = parseInt(questionsToPost[i].time_to_answer, 10);
        }

        if (images[i].type === 'bank') {
          questionsToPost[i].image_id = images[i].file;
        } else if (images[i].type === 'file') {
          questionsToPost[i].image_id = imagesPostRes.data[imagesIndexToFile[i]].id;
        } else if (images[i].type === 'index') {
          questionsToPost[i].image_id = imagesPostRes.data[imagesIndexToFile[images[i].file]].id;
        } else {
          questionsToPost[i].image_id = null;
        }
      }

      // post questions
      if (eval_id) {
        await postBulkQuestionsWithEvaluation(questionsToPost, eval_id);
      } else {
        await postBulkQuestions(questionsToPost);
      }
      setLoading(false);
      alert(
        `Las preguntas se importaron correctamente. Se importaron ${questionsToPost.length} preguntas.`
      );
      navigate(-1);
    } catch (err) {
      Sentry.captureException(err);
      if (err?.response?.data) {
        Sentry.captureMessage(err.response.data);
      }
      alert('Ocurrió un error, intente de nuevo.');
    }
    setLoading(false);
  };

  return (
    <BoxContainer>
      <div className='antialiased font-sans w-full'>
        <div className='divide-y divide-primary'>
          <HeaderContainer breadcrumb={itemsBreadcrumb} actions={actions} />
          <div className='flex flex-col items-center align-center py-8 px-4 sm:px-8 min-w-full w-full'>
            {!unit_id || unit_id === '0' ? (
              <div>
                <Typography>
                  Seleccione la unidad de aprendizaje (preexistente o nueva) *
                </Typography>
                <div style={styles.learunitInputContainer}>
                  <Select
                    name='learnunits_id'
                    label='Unidad Preexistente'
                    value={selectedLearnunit}
                    onChange={({ target }) => {
                      setSelectedLearnunit(target.value);
                      setNewLearnunit('');
                    }}
                    options={learnunits}
                    sx={{ minWidth: '200px' }}
                  />
                  <TextField
                    name='name'
                    label='Unidad Nueva'
                    value={newLearnunit}
                    onChange={({ target }) => {
                      setSelectedLearnunit('');
                      setNewLearnunit(target.value);
                    }}
                  />
                </div>
              </div>
            ) : null}
            {role === 'ADMIN' ? (
              <Select
                name='owner_id'
                label='Profesor'
                value={ownerId}
                onChange={e => setOwnerId(e.target.value)}
                options={teachers}
                sx={{ minWidth: '200px', mt: '2rem' }}
                required
              />
            ) : null}
            <Button
              sx={{ mt: 4 }}
              onClick={downloadTemplate}
              startIcon={<FileDownloadOutlinedIcon />}
              variant='contained'>
              Descargar plantilla (modelo)
            </Button>
            <Typography sx={{ my: 4 }} variant='body1'>
              Ocupa la plantilla (modelo) para subir de manera correcta los campos de las preguntas.
              Vienen 3 preguntas de ejemplo y en la hoja "Ayuda" está la explicación y las opciones
              a colocar en cada columna.
              <br />
              <b>NO MODIFICAR EL ORDEN DE LAS COLUMNAS.</b>
            </Typography>
            <FileUpload
              label='Elegir archivo'
              onChange={e => handleFile(e.target.files[0])}
              accept='.xlsx'
              startIcon={<FileUploadOutlinedIcon />}
            />
          </div>
          {questions.length > 0 && images.length > 0 && !loading ? (
            <div className='py-8 px-4 sm:px-8 min-w-full w-full'>
              <Button
                sx={{ mb: 2 }}
                onClick={importQuestions}
                startIcon={<CloudUploadOutlinedIcon />}
                variant='contained'
                color='secondary'>
                Importar Preguntas
              </Button>
              <ImportQuestionsTable questions={questions} images={images} setImages={setImages} />
            </div>
          ) : null}
          {loading ? <Loading /> : null}
        </div>
      </div>
    </BoxContainer>
  );
};

export default ImportQuestions;

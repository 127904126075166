import React, { useState } from 'react';

import Form from '../../style-components/Form/Form.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import { patchBibliography, postBibliography } from '../../models/bibliography.js';

function BibliographyForm({ bibliography, method, course_learnunit_id, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    name: bibliography?.name || '',
    url: bibliography?.url || '',
    details: bibliography?.details || '',
  });

  const handleSubmit = async () => {
    setLoading(true);
    if (method === 'POST') {
      const response = await postBibliography(formState, course_learnunit_id);
      if (response.status === 200 || response.status === 201) {
        alert('Bibliografía añadida exitosamente.');
        setOpen(false);
        window.location.reload();
      } else {
        alert('Error al añadir bibliografía. Intente de nuevo.');
      }
    } else if (method === 'PATCH') {
      const response = await patchBibliography(bibliography.id, formState);
      if (response.status === 200 || response.status === 201) {
        alert('Bibliografía editada con éxito.');
        setOpen(false);
        window.location.reload();
      } else {
        alert('Error al editar bibliografía. Intente de nuevo.');
      }
    }
    setLoading(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  return (
    <Form
      title={method === 'POST' ? 'Añadir Bibliografía' : 'Editar Bibliografía'}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      <TextField
        label='Nombre'
        value={formState.name}
        onChange={handleChange}
        name='name'
        required
        placeholder='Laminario'
      />
      <TextField
        label='URL'
        value={formState.url}
        onChange={handleChange}
        name='url'
        required
        placeholder='https://laminarioanatomico.medicina.uc.cl/'
      />
      <TextField
        label='Descripción'
        value={formState.details}
        onChange={handleChange}
        name='details'
        placeholder='Laminario de imágenes y videos anatómicos.'
        multiline
      />
    </Form>
  );
}

export default BibliographyForm;

import React, { useState } from 'react';

import Form from '../../style-components/Form/Form.jsx';
import TextField from '../../style-components/Form/TextField.jsx';
import Select from '../../style-components/Form/Select.jsx';
import { postUniversity, patchUniversity } from '../../models/universities.js';
import { CountriesList } from '../constants/countries.js';

const UniversityForm = ({ university, method, setOpen }) => {
  const [formState, setFormState] = useState({
    name: university?.name ?? '',
    country: university?.country ?? '',
    city: university?.city ?? '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (method === 'POST') {
      const response = await postUniversity(formState);
      setLoading(false);
      if (response.status !== 200 && response.status !== 201) {
        alert('Error al añadir universidad');
        return;
      }
      alert('Universidad añadida con éxito.');
      setOpen(false);
      window.location.reload();
    } else if (method === 'PATCH') {
      const response = await patchUniversity(university.id, formState);
      setLoading(false);
      if (response.status !== 200 && response.status !== 201) {
        alert('Error al añadir universidad');
        return;
      }
      alert('Universidad editada con éxito.');
      setOpen(false);
      window.location.reload();
    }
  };

  return (
    <Form
      title={method === 'POST' ? 'Añadir Universidad' : 'Editar Universidad'}
      onSubmit={handleSubmit}
      onClose={() => setOpen(false)}
      loading={loading}>
      <TextField
        label='Nombre'
        value={formState.name}
        onChange={handleChange}
        name='name'
        required
        placeholder='Universidad'
      />
      <Select
        label='País'
        value={formState.country}
        onChange={handleChange}
        name='country'
        required
        options={CountriesList.map(country => ({ value: country, label: country }))}
      />
      <TextField
        label='Ciudad'
        value={formState.city}
        onChange={handleChange}
        name='city'
        required
        placeholder='Santiago'
      />
    </Form>
  );
};

export default UniversityForm;
